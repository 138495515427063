import React, { ReactElement, ReactNode } from 'react';
import { FallbackRender } from '@sentry/react/types/errorboundary';

import { NonRetryableSystemError } from 'app/pages/error/non-retryable-system-error';
import { FatalErrorBoundary } from 'app/components/error';
import { ErrorTypes, FriendlyErrorMessage, GlobalError } from 'app/store/root-types';
import { useAppSelector } from 'app/hooks/redux-thunk';

import { EmojiDetective, EmojiHouse, EmojiSeeNoEvilMonkey, EmojiThinkingFace } from 'app/media';
import { useTranslation } from 'react-i18next';

interface Props {
  children: ReactNode;
}

export const GlobalFatalErrorBoundary: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const globalError = useAppSelector((state) => state.core.error);

  const getErrorMessage = ({ message }: GlobalError) => {
    switch (message) {
      case FriendlyErrorMessage.NO_SERVICES_FOR_LOCATION:
        return t('errors.nonRetryableSystemError.NO_SERVICES_FOR_LOCATION');
      case FriendlyErrorMessage.BROWSER_COOKIES_DISABLED:
        return t('errors.nonRetryableSystemError.BROWSER_COOKIES_DISABLED');
      case FriendlyErrorMessage.NO_LOCATIONS_FOUND_FOR_CUSTOMER:
        return t('errors.nonRetryableSystemError.NO_LOCATIONS_FOUND_FOR_CUSTOMER');
      case FriendlyErrorMessage.NO_ACCOUNTS_FOUND_FOR_CUSTOMER:
        return t('errors.nonRetryableSystemError.NO_ACCOUNTS_FOUND_FOR_CUSTOMER');
      case FriendlyErrorMessage.UNKNOWN_CUSTOMER_STATUS:
        return t('errors.nonRetryableSystemError.UNKNOWN_CUSTOMER_STATUS');
      case FriendlyErrorMessage.UKNOWN_ERROR:
      default:
        return t('errors.nonRetryableSystemError.defaultMessage');
    }
  };

  const getErrorImage = ({ message }: GlobalError) => {
    switch (message) {
      case FriendlyErrorMessage.NO_LOCATIONS_FOUND_FOR_CUSTOMER:
        return <EmojiHouse />;
      case FriendlyErrorMessage.NO_SERVICES_FOR_LOCATION:
      case FriendlyErrorMessage.BROWSER_COOKIES_DISABLED:
      case FriendlyErrorMessage.NO_ACCOUNTS_FOUND_FOR_CUSTOMER:
        return <EmojiDetective />;
      case FriendlyErrorMessage.UNKNOWN_CUSTOMER_STATUS:
        return <EmojiThinkingFace />;
      case FriendlyErrorMessage.UKNOWN_ERROR:
      default:
        return <EmojiSeeNoEvilMonkey />;
    }
  };

  const globalFallbackErrorHandler: FallbackRender = (errorData) =>
    errorTypeMapper({
      type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
      message: errorData.error.name,
      error: errorData.error,
    });

  const errorTypeMapper = (error: GlobalError): ReactElement => (
    <NonRetryableSystemError message={getErrorMessage(error)} image={getErrorImage(error)} />
  );

  return (
    <FatalErrorBoundary fallback={globalFallbackErrorHandler}>
      {globalError ? errorTypeMapper(globalError) : props.children}
    </FatalErrorBoundary>
  );
};
