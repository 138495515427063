import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { voipSettingsTabs } from 'app/utils/navigation-utils';
import { useAppDispatch } from 'app/hooks/redux-thunk';
import { FdsButton, FdsCheckbox, FdsRadio, FdsRadioItem } from '@lyse-as/formds-react';
import { useVoipSettings } from '../voip-common';
import { fetchVoipBarringSettings, updateVoipBarringSettings } from 'app/store/actions/voip-settings-thunks';
import { FdsRadioCustomEvent, FdsRadioItemCustomEvent } from '@lyse-as/formds-core';
import { FetchStatus } from 'app/store/root-types';
import { useEffect, useState } from 'react';
import { NumberDropDown } from '../components/number-dropdown';
import { VoipSettingsTitle } from '../components/voip-settings-title';
import { VoipContainer } from './container';
import styles from '../voip-common.module.scss';

interface CatalogReservationsTemplate {
  none: MinesiderBackend.DirectorySettings;
  numberAndAddress: MinesiderBackend.DirectorySettings;
  address: MinesiderBackend.DirectorySettings;
}

export const VoipBarring: ActiveCustomerLocationPage = ({ userContext }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedPhoneNumber, setSelectedSettings, selectedSettings, barring, updateFetchStatus, fetchData } =
    useVoipSettings();

  const [localChanges, setLocalChanges] = useState<MinesiderBackend.VoipSelfcareSettings>();

  useEffect(() => {
    fetchData();
  }, []);

  const catalogSettings: CatalogReservationsTemplate = {
    none: {
      reservationOfCatalogueAddress: false,
      reservationOfDirectory: false,
      reservationOfElectronicDirectory: false,
    },
    numberAndAddress: {
      reservationOfCatalogueAddress: false,
      reservationOfDirectory: true,
      reservationOfElectronicDirectory: true,
    },
    address: {
      reservationOfCatalogueAddress: true,
      reservationOfDirectory: false,
      reservationOfElectronicDirectory: false,
    },
  };

  useEffect(() => {
    if (selectedPhoneNumber && !barring[selectedPhoneNumber]) {
      dispatch(fetchVoipBarringSettings(selectedPhoneNumber));
    }
    if (selectedPhoneNumber && barring[selectedPhoneNumber]?.fetchStatus === FetchStatus.FULFILLED) {
      setLocalChanges(barring[selectedPhoneNumber].settings);
    }
  }, [selectedPhoneNumber, barring]);

  if (!selectedSettings || !selectedPhoneNumber) {
    return null;
  }

  function onSave() {
    if (localChanges) {
      dispatch(updateVoipBarringSettings(localChanges));
    }
  }

  function getSelectedCatalogOption() {
    const selectedOption = Object.entries(catalogSettings).find((settings) => {
      const [_property, catalogSettings] = settings as [string, MinesiderBackend.DirectorySettings];
      const isSelected = Object.entries(catalogSettings).every((entry) => {
        const [name, toggled] = entry as [keyof MinesiderBackend.DirectorySettings, boolean];
        return localChanges?.directorySettings?.[name] == toggled;
      });
      return isSelected;
    });
    return selectedOption?.[0] ?? 'none';
  }

  function selectNumber(phone: string) {
    setSelectedSettings(phone);
  }

  function toggleCheckBox(property: keyof MinesiderBackend.BarringSettings) {
    setLocalChanges((current) => {
      return {
        ...current,
        barringSettings: {
          ...current?.barringSettings,
          [property]: !current?.barringSettings?.[property],
        },
      };
    });
  }

  function selectDirectory(catalog: string) {
    setLocalChanges((current) => {
      return {
        ...current,
        directorySettings: {
          ...catalogSettings[catalog as keyof CatalogReservationsTemplate],
        },
      };
    });
  }

  const isLoading = !barring[selectedPhoneNumber] || updateFetchStatus === FetchStatus.PENDING;

  return (
    <VoipContainer services={userContext.services}>
      <div className={styles.container}>
        <ContainerFixed className={styles.tabbedContainer}>
          <VoipSettingsTitle />

          <TabbedContent selectedTabKey={'barring'} tabList={voipSettingsTabs}>
            <ContainerFixed isNarrow={true}>
              <div className={styles.section}>
                <NumberDropDown selectNumber={selectNumber} selectedPhoneNumber={selectedPhoneNumber} />
              </div>
              {barring[selectedPhoneNumber] && (
                <div className={isLoading ? styles.loadingOverlay : undefined}>
                  <section className={styles.section}>
                    <Typography variant="formds-lead" bold={true} component="h2">
                      {t('pages.voip.barring.blockLabel')}
                    </Typography>
                    {localChanges?.barringSettings &&
                      Object.entries(localChanges?.barringSettings)
                        .filter((setting) => setting[1] !== null)
                        .map((settings) => {
                          const [property] = settings as [keyof MinesiderBackend.BarringSettings, boolean];
                          return (
                            <FdsCheckbox
                              key={property}
                              name={property}
                              checkboxId={property}
                              defaultChecked={localChanges?.barringSettings?.[property]}
                              onFdsCheckboxChecked={() => toggleCheckBox(property)}
                            >
                              <Typography variant="formds-body" component="label" htmlFor={property}>
                                {t(`pages.voip.barring.barringBoxes.DYNAMIC_KEYS.${property}`)}
                              </Typography>
                            </FdsCheckbox>
                          );
                        })}
                  </section>
                  <section className={styles.section}>
                    <Typography variant="formds-lead" bold={true} component="h2">
                      {t('pages.voip.barring.catalogLabel')}
                    </Typography>

                    <FdsRadio
                      vertical={true}
                      label={t('pages.voip.barring.catalogLabel')}
                      hideLabel={true}
                      defaultValue={getSelectedCatalogOption()}
                    >
                      {catalogSettings &&
                        Object.entries(catalogSettings).map((settings) => {
                          const [property] = settings as [string, MinesiderBackend.DirectorySettings];
                          return (
                            <FdsRadioItem
                              key={property}
                              value={property}
                              onClick={(e) => selectDirectory(e.currentTarget.value)}
                            >
                              {t(`pages.voip.barring.catalogOptions.DYNAMIC_KEYS.${property}`)}
                            </FdsRadioItem>
                          );
                        })}
                    </FdsRadio>
                  </section>
                  <FdsButton
                    onClick={onSave}
                    disabled={updateFetchStatus === FetchStatus.PENDING}
                    isLoading={updateFetchStatus === FetchStatus.PENDING}
                  >
                    {t('pages.voip.barring.cta.save')}
                  </FdsButton>
                </div>
              )}
            </ContainerFixed>
          </TabbedContent>
        </ContainerFixed>
      </div>
    </VoipContainer>
  );
};
