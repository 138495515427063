import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { FdsAlert, FdsTextField } from '@lyse-as/formds-react';

import { ContainerFixed } from 'app/components/container';
import { ContainerLayoutMode } from 'app/components/container/container';
import { Typography as T } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { CustomerPage } from 'app/pages/page-wrapper';
import { getInvoicePaymentAgreementParameters, getInvoicePaymentInformation } from 'app/store/actions/invoice-thunks';
import { NetsAvtaleGiroForm, NetsAvtaleType } from './nets-avtalegiro-form';
import { ParamUtils } from 'app/utils/param-utils';
import { FetchStatus } from 'app/store/root-types';
import { navigationService } from 'app/service/navigation/navigation-service';

import styles from './invoice-avtalegiro.module.scss';

export const InvoiceAvtaleGiro: CustomerPage = () => {
  const { t } = useTranslation();
  const { paymentAgreement, paymentInformation } = useAppSelector((state) => state.invoice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInvoicePaymentAgreementParameters());
    if (!paymentInformation?.paymentMethod) {
      dispatch(getInvoicePaymentInformation());
    }
  }, []);

  // The following test account may be used for testing in test environment(MTF):
  const avtaleGiroTestAccount: MinesiderBackend.PaymentAgreement = {
    ...paymentAgreement.data,
    companyAccountNo: '11110500019',
    kid: '285332022028875',
  };

  const useAvtaleGiroTestAccount = ParamUtils.getQueryParam('test') && window.location.hostname !== 'www.altibox.no';

  return (
    <ContainerFixed mode={ContainerLayoutMode.Medium} className={styles.pageContainer}>
      {useAvtaleGiroTestAccount && (
        <FdsAlert variant="info" className={styles.testAccountMessage}>
          Using Mastercard test account no. 11110500019 due to url parameter "test". (Test mode is unavailable on
          www.altibox.no).
        </FdsAlert>
      )}
      <T variant="formds-altibox-subtitle-primary" component="h1" bold={true} className={styles.pageHeading}>
        {t('pages.invoice.invoiceAvtaleGiro.heading')}
      </T>
      <T variant="formds-body" component="p" className={styles.paragraph}>
        {t('pages.invoice.invoiceAvtaleGiro.avtaleGiroDescription')}
      </T>

      {paymentInformation?.paymentMethod === 'AVTALEGIRO' ? (
        <FdsAlert variant="info" disableDismiss={true} className={styles.customerHasAvtaleGiro}>
          <strong slot="alert-heading">{t('pages.invoice.invoiceAvtaleGiro.customerHasAvtaleGiro')}</strong>
        </FdsAlert>
      ) : (
        <T variant="formds-body" component="p" className={styles.paragraph}>
          {t('pages.invoice.invoiceAvtaleGiro.amountLimit')}
        </T>
      )}

      {paymentAgreement.data && paymentInformation?.paymentMethod !== 'AVTALEGIRO' && (
        <NetsAvtaleGiroForm
          paymentAgreement={useAvtaleGiroTestAccount ? avtaleGiroTestAccount : paymentAgreement.data}
          returnUrl={`${window.origin}${navigationService.PAGES.invoiceAvtaleGiroReceipt.url}`}
          netsAvtaleType={NetsAvtaleType.AvtaleGiro}
          disableSubmitButton={paymentAgreement.fetchStatus !== FetchStatus.FULFILLED}
        >
          <FdsTextField
            label={t('pages.invoice.invoiceAvtaleGiro.amountLimitInputLabel')}
            name="amountLimit"
            description={t('pages.invoice.invoiceAvtaleGiro.amountLimitInputDescription')}
            className={styles.amountLimitInput}
            required={true}
            maxLength={7}
          />
        </NetsAvtaleGiroForm>
      )}

      <T variant="formds-common-subtitle-primary" component="h2" bold={true} className={styles.eFakturaHeading}>
        {t('pages.invoice.invoiceAvtaleGiro.eFakturaHeading')}
      </T>
      <T variant="formds-body" component="p" className={styles.eFakturaInformation}>
        {t('pages.invoice.invoiceAvtaleGiro.eFakturaInformation')}
      </T>
    </ContainerFixed>
  );
};
