import { useTranslation } from 'react-i18next';

import { iso8601ToDayAndFullMonth, iso8601ToFullMonthAndYear } from 'app/utils/date-utils';
import { settlementIconStates, SettlementState } from '../settlement-state/settlement-state';
import { CopyButton } from '../copy-button/copy-button';
import { DescriptionList, DescriptionListPair } from 'app/components/description-list/description-list';
import {
  GtmDataLayer,
  GtmElementCategory,
  GtmElementCta,
  pushDataLayer,
  UserActionScenario,
} from 'app/utils/gtm-tracking-utils';
import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './invoice-overview.module.scss';

interface Props {
  invoice: MinesiderBackend.Invoice;
  paymentInformation?: MinesiderBackend.PaymentInformation;
  showFullDetails?: boolean;
  elementCategory: GtmElementCategory;
}

export const InvoiceOverview = ({ invoice, showFullDetails, paymentInformation, elementCategory }: Props) => {
  const { t } = useTranslation();
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  const copyButtonCallback = (
    userActionScenario:
      | UserActionScenario.INVOICE_COPY_ACCOUNT_NUMBER_CLICKED
      | UserActionScenario.INVOICE_COPY_KID_CLICKED,
  ) => {
    const dataLayer: GtmDataLayer = {
      element_category: elementCategory,
      element_cta:
        userActionScenario === UserActionScenario.INVOICE_COPY_KID_CLICKED
          ? GtmElementCta.FAKTURA_KOPIER_KID
          : GtmElementCta.FAKTURA_KOPIER_KONTONUMMER,
      element_status: invoice.settlementState,
      element_description: iso8601ToFullMonthAndYear(invoice.billDate || ''),
    };

    pushDataLayer(userActionScenario, selectedCustomerLocation, dataLayer);
  };

  const overviewList: DescriptionListPair[] = [
    {
      label: {
        value: iso8601ToFullMonthAndYear(invoice.billDate || ''),
        variant: 'formds-common-subtitle-secondary',
      },
      description: {
        value: invoice.taxIncludedSum || '',
        variant: 'formds-common-subtitle-secondary',
      },
    },
    {
      label: {
        value: t('pages.invoice.details.settlementState.status'),
      },
      description: {
        value: settlementIconStates({
          settlementState: invoice.settlementState as SettlementState,
          dueDate: invoice.dueDate,
        }).getIcon(),
      },
    },
    {
      label: {
        value: t('pages.invoice.details.dueDate'),
      },
      description: {
        value: iso8601ToDayAndFullMonth(invoice.dueDate || ''),
      },
    },
    {
      label: {
        value: t('pages.invoice.details.kidNumber'),
      },
      description: {
        value: (
          <>
            {invoice.kidNumber}
            {invoice.kidNumber && (
              <CopyButton
                value={invoice.kidNumber}
                title={t('pages.invoice.details.copyKidNumber')}
                callback={() => copyButtonCallback(UserActionScenario.INVOICE_COPY_KID_CLICKED)}
              />
            )}
          </>
        ),
      },
    },
    {
      label: {
        value: t('pages.invoice.details.accountNumber'),
      },
      description: {
        value: (
          <>
            {paymentInformation?.partnerBankAccount}
            <CopyButton
              value={paymentInformation?.partnerBankAccount || ''}
              title={t('pages.invoice.details.copyAccountNumber')}
              callback={() => copyButtonCallback(UserActionScenario.INVOICE_COPY_ACCOUNT_NUMBER_CLICKED)}
            />
          </>
        ),
      },
    },
  ];

  let detailedList: DescriptionListPair[] = [];

  if (showFullDetails) {
    detailedList = [
      {
        label: {
          value: t('pages.invoiceDetails.number'),
        },
        description: {
          value: invoice.billNumber,
        },
      },
      {
        label: {
          value: t('pages.invoiceDetails.issued'),
        },
        description: {
          value: iso8601ToDayAndFullMonth(invoice.billDate || ''),
        },
      },
      {
        label: {
          value: t('pages.invoiceDetails.paymentMethod'),
        },
        description: {
          value: t(`pages.invoice.DYNAMIC_KEYS.${paymentInformation?.paymentMethod}`),
        },
      },
      {
        label: {
          value: t('pages.invoiceDetails.deliveryMethod'),
        },
        description: {
          value: t(`pages.invoice.DYNAMIC_KEYS.${paymentInformation?.deliveryMethod}`),
        },
      },
    ];
  }

  return <DescriptionList className={styles.container} data={overviewList.concat(detailedList)} />;
};
