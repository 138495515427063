import Cookies from 'js-cookie';

export const ACCESS_TOKEN_COOKIE = 'sso_access_token';
export const SESSION_TICKET_COOKIE = 'sessionTicketApi';
export const USER_COOKIE = 'user';
export const USER_CONTEXT_COOKIE = 'user_context';
export const SELECTED_LOCATION_COOKIE = 'locationPicker_SelectedLocation';
export const LOGIN_REDIRECT_URL = 'loginRedirectUrl';
export const AUTH_STATE_PARAM = 'authRequestStateParam';
export const INFORMATION_PROMPT_GRACE_TIME = 'information_prompt_grace_time';
export const WPBOX_SSOLOGIN_REDIRECT_URL = 'wpboxSsoLoginRedirectUrl'; // Target URL from Wpbox using the login callback page on Minesider. Cookie set in /web-apps/altibox/js/altibox/src/auth/sso.ts

export function clearAuthCookies(): void {
  return [
    SESSION_TICKET_COOKIE,
    ACCESS_TOKEN_COOKIE,
    USER_COOKIE,
    USER_CONTEXT_COOKIE,
    SELECTED_LOCATION_COOKIE,
    LOGIN_REDIRECT_URL,
    AUTH_STATE_PARAM,
  ].forEach((cookie) => Cookies.remove(cookie));
}
