import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps, NavLinkRenderProps, useLocation } from 'react-router-dom';

import { useAppSelector } from 'app/hooks/redux-thunk';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { Home, Tv, Wifi, Konto, Telephone } from 'app/media';
import { navigationService } from 'app/service/navigation/navigation-service';
import { CustomerType, isActiveLocation, MainContext } from 'app/store/types/user-context-types';
import { Service } from 'app/service/navigation/pages/page-types';
import { CONTEXT_PATH, useAppNavigation } from 'app/utils/navigation-utils';
import {
  getGtmFullUrl,
  GtmDataLayer,
  GtmElementCategory,
  pushDataLayer,
  UserActionScenario,
} from 'app/utils/gtm-tracking-utils';

import styles from './main-menu.module.scss';

interface Props {
  context: MainContext;
}
interface LoggingNavLinkProps extends NavLinkProps {
  gtmElementCta: string;
}

export const MainMenu: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const services = isActiveLocation(props.context) ? props.context.services : [];

  const isEnterpriseCustomer = () => props.context.customerType === CustomerType.BEDRIFT;

  const homePageLinkClassName = (navDataActive: boolean) => {
    const sanitizedPathname = location.pathname.split('/').join('');
    const sanitizedHomeUrl = navigationService.PAGES.home.url.split('/').join('');
    const isActive = navDataActive && sanitizedPathname === sanitizedHomeUrl;
    return isActive ? styles.active : '';
  };

  const gtmNavLink: React.FC<LoggingNavLinkProps> = ({ to, children, gtmElementCta, ...rest }) => {
    const { goToPath } = useAppNavigation();

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
      event.preventDefault();

      const gtmDataLayer: GtmDataLayer = {
        element_url: getGtmFullUrl(to as string),
        element_cta: gtmElementCta,
        element_category: GtmElementCategory.TOP_NAVIGATION,
      };

      pushDataLayer(UserActionScenario.MAIN_MENU_LINK_CLICKED, props.context, gtmDataLayer);

      goToPath(to as string);
    };

    const getClassName = (navData: NavLinkRenderProps) => {
      if (gtmElementCta === 'Forside') {
        return homePageLinkClassName(navData.isActive);
      }
      return navData.isActive ? styles.active : '';
    };

    return (
      <NavLink to={to} onClick={handleClick} className={(navData) => getClassName(navData)} {...rest}>
        {children}
      </NavLink>
    );
  };

  return (
    <>
      <nav id="main-menu" className={styles.mainMenu} aria-label={t('features.mainMenu.Hovedmeny')}>
        <ul>
          <li>
            {gtmNavLink({
              to: navigationService.PAGES.home.url,
              gtmElementCta: 'Forside',
              children: (
                <>
                  <Home aria-hidden="true" className={styles.fill} />
                  <div>
                    <span>{t(navigationService.PAGES.home.i18n)}</span>
                  </div>
                </>
              ),
            })}
          </li>

          {services.includes(Service.Tv) && !isEnterpriseCustomer() && (
            <li>
              {gtmNavLink({
                to: navigationService.PAGES.tv.url,
                gtmElementCta: 'Tv',
                children: (
                  <>
                    <Tv aria-hidden="true" className={styles.fill} />
                    <div>
                      <span>{t(navigationService.PAGES.tv.i18n)}</span>
                    </div>
                  </>
                ),
              })}
            </li>
          )}
          {services.includes(Service.Internet) && (
            <li>
              {gtmNavLink({
                to: navigationService.PAGES.internet.url,
                gtmElementCta: 'Internett',
                children: (
                  <>
                    <Wifi aria-hidden="true" className={styles.stroke} />
                    <div>
                      <span>{t(navigationService.PAGES.internet.i18n)}</span>
                    </div>
                  </>
                ),
              })}
            </li>
          )}
          {isEnterpriseCustomer() && (
            <li>
              {gtmNavLink({
                to: navigationService.PAGES.telephone.url,
                gtmElementCta: 'Telefoni',
                children: (
                  <>
                    <Telephone aria-hidden="true" className={styles.fill} />
                    <div>
                      <span>{t(navigationService.PAGES.telephone.i18n)}</span>
                    </div>
                  </>
                ),
              })}
            </li>
          )}

          <li>
            {gtmNavLink({
              to: navigationService.PAGES.account.url,
              gtmElementCta: 'Konto',
              children: (
                <>
                  <Konto aria-hidden="true" className={styles.fill} />
                  <div>
                    <span>{t(navigationService.PAGES.account.i18n)}</span>
                  </div>
                </>
              ),
            })}
          </li>
        </ul>
      </nav>
      <Breadcrumbs />
    </>
  );
};
