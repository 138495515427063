import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FdsAlert, FdsButton, FdsCheckbox, FdsIcon, FdsLoader, FdsRadio, FdsRadioItem } from '@lyse-as/formds-react';

import { ContainerFixed } from 'app/components/container';
import { useAnnounceNavigation } from 'app/hooks';
import { FetchStatus } from 'app/store/root-types';
import { CustomerPage } from 'app/pages/page-wrapper';
import { fetchConsent, updateConsents } from 'app/store/actions/consent-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { Breakpoint, Typography } from 'app/component-library-wave';

import styles from './consent.module.scss';

type Consent = 'yes' | 'no' | undefined;

export const Consent: CustomerPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { announceElement } = useAnnounceNavigation();

  const fetchStatus = useAppSelector((state) => state.consent.fetchStatus);
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const consent = useAppSelector((state) => state.consent.consent);
  const [selectedEmailConsent, setSelectedEmailConsent] = useState<Consent>();
  const [selectedSmsConsent, setSelectedSmsConsent] = useState<Consent>();
  const [selectedConsent, setSelectedConsent] = useState<Consent>();

  useEffect(() => {
    switch (consent?.generalMarketingEmail) {
      case undefined:
        setSelectedEmailConsent(undefined);
        break;
      case true:
        setSelectedEmailConsent('yes');
        break;
      default:
        setSelectedEmailConsent('no');
        break;
    }
    switch (consent?.generalMarketingSms) {
      case undefined:
        setSelectedSmsConsent(undefined);
        break;
      case true:
        setSelectedSmsConsent('yes');
        break;
      default:
        setSelectedSmsConsent('no');
        break;
    }
    if (consent?.generalMarketingEmail || consent?.generalMarketingSms) {
      setSelectedConsent('yes');
    }
    if (consent?.generalMarketingEmail === false && consent.generalMarketingSms === false) {
      setSelectedConsent('no');
    }
  }, [consent]);

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchConsent());
    }
  }, [dispatch, fetchStatus]);

  useEffect(() => {
    if (selectedConsent === 'yes' && selectedSmsConsent === 'no' && selectedEmailConsent === 'no') {
      setSelectedConsent('no');
    }
  }, [selectedEmailConsent, selectedSmsConsent, selectedConsent]);

  const onChangeConsent = (option: Consent) => {
    setSelectedConsent(option);
    setSelectedEmailConsent(option);
    setSelectedSmsConsent(option);
  };

  const onToggleMethod = (method: 'sms' | 'email') => {
    if (method === 'sms') {
      setSelectedSmsConsent((current) => (current === 'yes' ? 'no' : 'yes'));
    }
    if (method === 'email') {
      setSelectedEmailConsent((current) => (current === 'yes' ? 'no' : 'yes'));
    }
  };

  const onSave = () => {
    if (!consent) {
      return;
    }

    const newConsents: MinesiderBackend.CrmUserConsents = {
      ...consent,
      generalMarketingEmail: selectedEmailConsent === 'yes',
      generalMarketingSms: selectedSmsConsent === 'yes',
    };

    dispatch(updateConsents(newConsents));
  };

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.consentContainer}>
        <Typography
          variant="headline4"
          component="h1"
          className={styles.consentHeader}
          ref={announceElement}
          maxBreakpoint={Breakpoint.TABLET}
        >
          {t('pages.consent.generalMarketing.heading')}
        </Typography>
        <Typography
          component="p"
          variant="paragraph2"
          className={styles.consentParagraph}
          maxBreakpoint={Breakpoint.MOBILE}
        >
          {t('pages.consent.generalMarketing.lead', { partnerName: selectedCustomerLocation?.partnerLegalName })}
        </Typography>

        {fetchStatus === FetchStatus.REJECTED && (
          <FdsAlert variant="warning" disableDismiss={true}>
            <strong slot="alert-heading">{t('errors.generalMarketing.apiRequestRejected')}</strong>
          </FdsAlert>
        )}
        {fetchStatus !== FetchStatus.REJECTED && (
          <>
            <div className={styles.card}>
              <FdsRadio
                label={t('pages.consent.generalMarketing.description', {
                  partnerName: selectedCustomerLocation?.partnerLegalName,
                })}
                vertical={true}
                disabled={fetchStatus === FetchStatus.PENDING}
                defaultValue={selectedConsent}
              >
                <FdsRadioItem onFdsRadioChecked={() => onChangeConsent('yes')} value={'yes'}>
                  <Typography component="p" variant="formds-body" bold={true}>
                    {t('pages.consent.generalMarketing.permit')}
                  </Typography>
                </FdsRadioItem>
                <FdsRadioItem onFdsRadioChecked={() => onChangeConsent('no')} value={'no'}>
                  <Typography component="p" variant="formds-body" bold={true}>
                    {t('pages.consent.generalMarketing.deny')}
                  </Typography>
                </FdsRadioItem>
              </FdsRadio>
              {selectedConsent === 'yes' && (
                <div className={styles.methodContainer}>
                  <Typography component="p" variant="formds-body">
                    {t('pages.consent.generalMarketing.methodHeading')}
                  </Typography>
                  <FdsCheckbox
                    name="email"
                    id="email"
                    defaultChecked={selectedEmailConsent === 'yes'}
                    onFdsCheckboxChecked={() => onToggleMethod('email')}
                  >
                    {t('pages.consent.generalMarketing.methodEmail')}
                  </FdsCheckbox>
                  <FdsCheckbox
                    name="sms"
                    id="sms"
                    onKeyDown={(e) => {
                      if (e.key === ' ') {
                        e.preventDefault();
                        onToggleMethod('sms');
                      }
                    }}
                    defaultChecked={selectedSmsConsent === 'yes'}
                    onFdsCheckboxChecked={() => onToggleMethod('sms')}
                  >
                    {t('pages.consent.generalMarketing.methodSms')}
                  </FdsCheckbox>
                </div>
              )}
              {selectedConsent === undefined && (
                <div className={styles.required}>
                  <FdsIcon name="info_filled" className={styles.infoIcon} />
                  <Typography component="span" variant="formds-body">
                    {t('pages.consent.generalMarketing.required')}
                  </Typography>
                </div>
              )}
            </div>
          </>
        )}
        <div className={styles.withdraw}>
          <Typography component="p" variant="formds-body">
            {t('pages.consent.generalMarketing.withdraw')}
          </Typography>
        </div>
        <FdsButton
          onClick={onSave}
          disabled={selectedConsent === undefined}
          isLoading={fetchStatus === FetchStatus.PENDING}
        >
          {t('pages.consent.ctaSave')}
        </FdsButton>
      </div>
    </ContainerFixed>
  );
};
