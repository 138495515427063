import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { iso8601ToFullMonthAndYear } from 'app/utils/date-utils';
import { GtmElementCategory, GtmElementCta, pushDataLayer, UserActionScenario } from 'app/utils/gtm-tracking-utils';
import { getInvoicePdf } from 'app/store/actions/invoice-thunks';

export const useDownloadInvoicePdf = () => {
  const { fetchStatus } = useAppSelector((state) => state.invoice.invoicePdf);

  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const customerCrmId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const dispatch = useAppDispatch();

  const downloadPdf = (invoice: MinesiderBackend.Invoice, elementCategory: GtmElementCategory) => {
    if (!invoice || !invoice.billNumber || !customerCrmId) {
      return;
    }
    dispatch(getInvoicePdf({ customerCrmId, invoiceId: invoice.billNumber }));

    pushDataLayer(UserActionScenario.INVOICE_DOWNLOAD_PDF_CLICKED, selectedCustomerLocation, {
      element_status: invoice.settlementState,
      element_category: elementCategory,
      element_description: iso8601ToFullMonthAndYear(invoice?.billDate || ''),
      element_cta: GtmElementCta.FAKTURA_LAST_NED_PDF,
    });
  };

  return { downloadPdf, downloadFetchStatus: fetchStatus };
};
