import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { RootState } from '../configure-store';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';
import { dismissVerificationPrompt } from './core-actions';

export const fetchConsent = createAsyncThunk('consent/fetchConsent', async (_, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const crmId = currentState.userContext.selectedCustomerLocation?.crmId;
  if (!crmId) {
    throw new Error('[consent-thunks] No customerId found');
  }
  return minesiderBackendService.getConsents(crmId);
});

export const updateConsents = createAsyncThunk<MinesiderBackend.CrmUserConsents, MinesiderBackend.CrmUserConsents>(
  'consent/updateConsents',
  async (consents, thunkApi) => {
    const currentState = thunkApi.getState() as RootState;
    const crmId = currentState.userContext.selectedCustomerLocation?.crmId;
    if (!crmId) {
      throw new Error('[consent-thunks] No customerId found');
    }

    return minesiderBackendService
      .updateConsents(crmId, consents)
      .then((consent) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'success',
            heading: i18n.t('features.toast.save.success'),
            role: 'status',
          }),
        );
        thunkApi.dispatch(dismissVerificationPrompt());
        return consent;
      })
      .catch((error) => {
        thunkApi.dispatch(
          addToastMessage({
            alertType: 'critical',
            heading: i18n.t('features.toast.save.rejected'),
            role: 'alert',
          }),
        );
        return thunkApi.rejectWithValue(error as MinesiderBackend.ErrorResponse);
      });
  },
);
