import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { Service } from 'app/service/navigation/pages/page-types';
import { ErrorTypes, FetchStatus, FriendlyErrorMessage } from 'app/store/root-types';
import { VoipError } from './error';
import { Spinner } from 'app/components/spinner';
import { raiseGlobalError } from 'app/store/actions/core-actions';

export const VoipContainer = ({ children, services }: { children: React.ReactNode; services: Service[] }) => {
  const { fetchStatus } = useAppSelector((state) => state.voipSettings.locations);
  const dispatch = useAppDispatch();

  if (!services.includes(Service.Voip || Service.TaVoip)) {
    dispatch(
      raiseGlobalError({
        message: FriendlyErrorMessage.NO_SERVICES_FOR_LOCATION,
        type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
      }),
    );
  }

  if (fetchStatus === FetchStatus.REJECTED) {
    return <VoipError />;
  }

  if (fetchStatus === FetchStatus.PENDING) {
    return <Spinner />;
  }

  return children;
};
