import { CancelSource, RestService } from 'app/api/common';
import { ActivateEmailAccountParameters } from 'app/store/actions/email-thunks';
import { RelocationData } from 'app/store/actions/relocation-actions';

class MinesiderBackendService {
  private readonly restService: RestService;

  constructor(cancelSource: CancelSource = new CancelSource()) {
    this.restService = cancelSource.service;
  }

  public readonly getEquipment = (
    languageKey: string,
    equipment: string[],
  ): Promise<MinesiderBackend.EquipmentEnrichment[]> =>
    this.restService.get<MinesiderBackend.EquipmentEnrichment[]>(`/equipment/${languageKey}/${equipment.join(',')}`);

  public readonly getUserContext = (): Promise<MinesiderBackend.UserContext> =>
    this.restService.get<MinesiderBackend.UserContext>(`/auth/usercontext`);

  public readonly getUserContextForCsr = (): Promise<MinesiderBackend.UserContext> =>
    this.restService.get<MinesiderBackend.UserContext>(`/auth/prov/usercontext`);

  public readonly getOperationalMessages = (siteId: number): Promise<MinesiderBackend.OperationalMessage[]> =>
    this.restService.get<MinesiderBackend.OperationalMessage[]>(`/operationalmessages/customer/${siteId}`);

  public readonly getNotifications = (
    customerCrmId: string,
    servicePointId: string,
  ): Promise<MinesiderBackend.CustomerNotification[]> =>
    this.restService.get<MinesiderBackend.CustomerNotification[]>(`/notifications/${customerCrmId}/${servicePointId}`);

  public readonly getConsents = (crmId: string): Promise<MinesiderBackend.CrmUserConsents> =>
    this.restService.get<MinesiderBackend.CrmUserConsents>(`/crmuser/${crmId}/consents`);

  public readonly updateConsents = (crmId: string, consents: MinesiderBackend.CrmUserConsents) =>
    this.restService.post<MinesiderBackend.CrmUserConsents>(`/crmuser/${crmId}/consents`, consents);

  public readonly getVoipUsage = (crmUserId: string, period?: string): Promise<MinesiderBackend.VoipUsage[]> =>
    this.restService.get(`/usages/${crmUserId}/voip` + (period ? `?period=${period}` : ''));

  public readonly getUserNeedToConfirm = (crmUserId: string): Promise<MinesiderBackend.ContactDetailsConfirmation> =>
    this.restService.get(`/crmuser/${crmUserId}/contactDetails`);

  public readonly confirmUserDetails = (crmUserId: string): Promise<unknown> =>
    this.restService.post(`/crmuser/${crmUserId}/contactDetails/confirm`);

  public readonly getCustomerDetails = (customerId: string): Promise<MinesiderBackend.CustomerDetails> =>
    this.restService.get<MinesiderBackend.CustomerDetails>(`/customers/${customerId}/details`);

  public readonly updateCustomerDetails = (
    customerId: string,
    customerDetails: MinesiderBackend.CustomerDetails,
  ): Promise<void> => this.restService.put<void>(`/customers/${customerId}/details`, customerDetails);

  public readonly setPin = (newPin: MinesiderBackend.SetPinRequest): Promise<void> =>
    this.restService.post<void>('/securities/pin', newPin);

  public readonly getPin = (customerId: string): Promise<MinesiderBackend.CustomerPin> =>
    this.restService.get<MinesiderBackend.CustomerPin>(`/securities/${customerId}/pin`);

  public readonly getNetworkSettings = (
    servicePointId: string,
    customerId: string,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.get<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/customerNetwork`,
    );

  public readonly setWifiSettings = (
    servicePointId: string,
    customerId: string,
    settings: MinesiderBackend.UpdateWifiNetwork[],
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.post<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/wifis`,
      settings,
    );

  public readonly setAccessPoints = (
    servicePointId: string,
    customerId: string,
    settings: MinesiderBackend.UpdateAccessPoint[],
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.put<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/accessPoints`,
      settings,
    );

  public readonly setWifiSetting = (
    servicePointId: string,
    customerId: string,
    wifiId: string,
    settings: MinesiderBackend.UpdateSingleWifiNetwork,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.post<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/wifis/${wifiId}`,
      settings,
    );

  public readonly updateNetwork = (
    customerId: string,
    servicePointId: string,
    network: MinesiderBackend.UpdateNetwork,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.post<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/network`,
      network,
    );

  public readonly setManagedWifiSetting = (
    servicePointId: string,
    customerId: string,
    wifiId: string,
    settings: MinesiderBackend.UpdateSingleWifiNetwork,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.post<MinesiderBackend.CustomerNetwork>(
      `/managedWifi/${customerId}/servicePoints/${servicePointId}/wifis/${wifiId}`,
      settings,
    );

  public readonly getSubscriptions = (crmId: string): Promise<MinesiderBackend.CrmUserSubscriptions> =>
    this.restService.get<MinesiderBackend.CrmUserSubscriptions>(`/crmuser/${crmId}/subscriptions`);

  public readonly updateSubscriptions = (
    crmId: string,
    subscriptions: MinesiderBackend.CrmUserSubscriptions,
  ): Promise<MinesiderBackend.CrmUserSubscriptions> =>
    this.restService.put<MinesiderBackend.CrmUserSubscriptions>(`/crmuser/${crmId}/subscriptions`, subscriptions);

  public readonly getServicePointDeliveryStatus = (
    servicePointId: string,
  ): Promise<MinesiderBackend.ServicePointDeliveryStatus> =>
    this.restService.get<MinesiderBackend.ServicePointDeliveryStatus>(`/servicepoint/${servicePointId}/deliveryStatus`);

  public readonly postServicePointCustomerDiggingStatus = (
    servicePointId: string,
    diggingStatus: { customerDiggingDone: boolean },
  ): Promise<MinesiderBackend.CustomerDiggingStatus> =>
    this.restService.post<MinesiderBackend.CustomerDiggingStatus>(
      `/servicepoint/${servicePointId}/setCustomerDiggingStatus`,
      diggingStatus,
    );

  public readonly getAddressAutocomplete = (address: string, limit: number = 20): Promise<MinesiderBackend.Address[]> =>
    this.restService.get<MinesiderBackend.Address[]>(`/address/autocomplete?address=${address}&limit=${limit}`);

  public readonly submitRelocation = (relocationData: RelocationData): Promise<RelocationData> =>
    this.restService.post<RelocationData>('/move/register', relocationData);

  public readonly getStreamingService = (
    siteId: number,
    portalId: string,
  ): Promise<MinesiderBackend.TvServiceContent> =>
    this.restService.get<MinesiderBackend.TvServiceContent>(`/streaming/v1/${siteId}/${portalId}`);

  public readonly getStreamingServices = (siteId: number): Promise<MinesiderBackend.TvServiceContent[]> =>
    this.restService.get<MinesiderBackend.TvServiceContent[]>(`/streaming/v1/${siteId}`);

  public readonly getStreamingAuthLink = (
    siteId: number,
    portalId: string,
    action: 'REGISTRATION' | 'RELEASE_POINTS' | 'RECOVERY',
  ) => this.restService.get<MinesiderBackend.AuthLink>(`/streaming/v1/${siteId}/${portalId}/${action}`);

  public readonly getPartner = (partnerNumber: string): Promise<MinesiderBackend.CmsPartner> =>
    this.restService.get<MinesiderBackend.CmsPartner>(`/partner/${partnerNumber}`, {
      headers: {
        XAnonymousRequest: 'true',
      },
    });

  public readonly getEmailAccountResponse = () => this.restService.get<MinesiderBackend.EmailServiceDetails>('/email');
  public readonly createNewEmailAccount = (
    emailAccount: MinesiderBackend.EmailAccountCreate,
  ): Promise<MinesiderBackend.EmailAccount> => this.restService.post(`/email/accounts`, emailAccount);

  public readonly deleteEmailAccount = (emailAccountId: string) =>
    this.restService.post(`/email/accounts/${emailAccountId}/delete`);

  public readonly activateEmailAccount = ({
    emailAccountId,
    firstname,
    lastname,
    password,
  }: ActivateEmailAccountParameters): Promise<MinesiderBackend.EmailAccount> =>
    this.restService.post(`/email/accounts/${emailAccountId}/activate`, { firstname, lastname, password });

  public readonly changeEmailAccountPassword = (
    emailAccountId: string,
    emailAccountPassword: MinesiderBackend.EmailAccountSetPassword,
  ): Promise<MinesiderBackend.EmailAccount> =>
    this.restService.post<MinesiderBackend.EmailAccount>(
      `/email/accounts/${emailAccountId}/password`,
      emailAccountPassword,
    );

  public readonly createFirewallRule = (
    customerId: string,
    servicePointId: string,
    rule: MinesiderBackend.UpdatePortForwarding,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.post<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/portForwarding`,
      rule,
    );

  public readonly updateFirewallRule = (
    customerId: string,
    servicePointId: string,
    ruleId: number,
    rule: MinesiderBackend.UpdatePortForwarding,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.put<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/portForwarding/${ruleId}`,
      rule,
    );

  public readonly deleteFirewallRule = (
    customerId: string,
    servicePointId: string,
    ruleId: number,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.delete<MinesiderBackend.CustomerNetwork>(
      `/customers/${customerId}/servicePoints/${servicePointId}/portForwarding/${ruleId}`,
    );

  public readonly changeEmailAccountName = (
    emailAccountId: string,
    emailAccountName: MinesiderBackend.EmailAccountSetSenderName,
  ): Promise<MinesiderBackend.EmailAccount> =>
    this.restService.post<MinesiderBackend.EmailAccount>(
      `/email/accounts/${emailAccountId}/sender/name`,
      emailAccountName,
    );

  public readonly getDiscountStatus = (
    customerId: string,
    discountType: string,
  ): Promise<MinesiderBackend.DiscountStatus> =>
    this.restService.get(`/customers/${customerId}/${discountType}/discountStatus`);

  public readonly getProducts = (siteId: number): Promise<MinesiderBackend.Product[]> =>
    this.restService.get(`/products?siteId=${siteId}`);

  public readonly getUsers = (crmCustomerId: string): Promise<MinesiderBackend.CrmUser[]> =>
    this.restService.get(`/crmuser`, { params: { crmCustomerId } });

  public readonly createUser = (user: MinesiderBackend.CreateCrmUser): Promise<MinesiderBackend.CrmUser> =>
    this.restService.post(`/crmuser`, user);

  public readonly resendVerificationEmail = (id: string): Promise<void> =>
    this.restService.post(`/crmuser/${id}/email/resend`);

  public readonly updateUser = (id: string, user: MinesiderBackend.UpdateCrmUser): Promise<MinesiderBackend.CrmUser> =>
    this.restService.put(`/crmuser/${id}`, user);

  public readonly deleteUser = (crmUserId: string, crmCustomerId: string): Promise<void> =>
    this.restService.patch(`/crmuser/${crmUserId}/deactivate?crmCustomerId=${crmCustomerId}`);

  public readonly getMovieRentalHistory = (customerId: string, period?: string): Promise<MinesiderBackend.VodUsage[]> =>
    this.restService.get(`/usages/${customerId}/vod` + (period ? `?period=${period}` : ''));

  public readonly getFixedWirelessAccessStatus = (
    customerId: string,
    servicePointId: string,
  ): Promise<MinesiderBackend.FixedWirelessAccessStatus> =>
    this.restService.get(`/customers/${customerId}/servicePoints/${servicePointId}/fixedWirelessStatus`);

  public readonly updateWifiAccessPoint = (
    customerId: string,
    servicePointId: string,
    id: number,
    settings: MinesiderBackend.UpdateSingleAccessPoint,
  ): Promise<MinesiderBackend.CustomerNetwork> =>
    this.restService.post(`/customers/${customerId}/servicePoints/${servicePointId}/wifiAccessPoints/${id}`, settings);

  public readonly logout = (): Promise<void> => this.restService.post('/auth/logout');

  public readonly getInvoices = (from: string, to: string): Promise<MinesiderBackend.Invoice[]> =>
    this.restService.get(`/invoice/invoices?from=${from}&to=${to}`);

  public readonly getInvoicePaymentInformation = (): Promise<MinesiderBackend.PaymentInformation> =>
    this.restService.get('/invoice/payment');

  public readonly getInvoicePaymentAgreementParameters = (
    countryCode: string,
  ): Promise<MinesiderBackend.PaymentAgreement> =>
    this.restService.get(`/invoice/payment/url?avtalegiro=true&efaktura=false&countryCode=${countryCode}`);

  public readonly getInvoiceLines = (invoiceId: string): Promise<MinesiderBackend.InvoiceUsage[]> =>
    this.restService.get(`/invoice/invoicelines/${invoiceId}`);

  public readonly postponeInvoice = (invoiceId: string): Promise<MinesiderBackend.InvoicePostpone> =>
    this.restService.post(`/invoice/postpone/${invoiceId}`);

  public readonly getInvoicePdf = (invoiceId: string, customerCrmId: string): Promise<ArrayBuffer> => {
    return this.restService.get(`/invoice/pdf/${customerCrmId}/${invoiceId}`, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/pdf' },
    });
  };

  public readonly getVoipLocations = (): Promise<MinesiderBackend.VoipLocation[]> =>
    this.restService.get<MinesiderBackend.VoipLocation[]>('/voip/locations');

  public readonly getVoipSettings = (phoneNumber: string): Promise<MinesiderBackend.VoipSettings> =>
    this.restService.get<MinesiderBackend.VoipSettings>(`/voip/settings/${phoneNumber}`);

  public readonly updateVoipSettings = (
    settings: MinesiderBackend.VoipSettings,
  ): Promise<MinesiderBackend.VoipSettings> => {
    return this.restService.post<MinesiderBackend.VoipSettings>(`/voip/settings/update`, settings);
  };

  public readonly getVoipBarringSettings = (phoneNumber: string): Promise<MinesiderBackend.VoipSelfcareSettings> =>
    this.restService.get<MinesiderBackend.VoipSelfcareSettings>(`/voip/barring/settings/${phoneNumber}`);

  public readonly updateVoipBarringSettings = (
    settings: MinesiderBackend.VoipSelfcareSettings,
  ): Promise<MinesiderBackend.VoipSelfcareSettings> => {
    return this.restService.post<MinesiderBackend.VoipSelfcareSettings>(`/voip/barring/settings/update`, settings);
  };
}

const minesiderBackendService = new MinesiderBackendService();

export { minesiderBackendService, MinesiderBackendService };
