import { CrmSystem, CustomerType } from 'app/store/types/user-context-types';
import { GtmDataLayer } from 'app/utils/gtm-tracking-utils';
import { Partner } from 'app/utils/partner-utils';

export enum FilterType {
  Whitelist = 'Whitelist',
  Blacklist = 'Blacklist',
}

interface PartnerVisibility {
  filterType: FilterType;
  partners: Partner[];
}

export interface Page {
  i18n: string;
  url: string;
  openInNewTab?: boolean;
  partnerVisibility?: PartnerVisibility;
  requireOneOfServices?: Service[];
  requireNotHavingAnyOfServices?: Service[];
  crm?: CrmSystem;
  customerType?: CustomerType;
  description?: string;
  gtmDataLayer?: GtmDataLayer;
}

export enum Service {
  Tv = 'TV',
  Voip = 'VOIP',
  Internet = 'INTERNET',
  OffnetInternet = 'OFFNET_INTERNET',
  ManagedWifi = 'MANAGED_WIFI',
  FixedWirelessAccess = 'FWA',
  Email = 'EMAIL',
  TvOTT = 'TV_OTT',
  TaVoip = 'TA_VOIP',
  EthernetAccess = 'ETHERNET_ACCESS',
  EthernetP2P = 'ETHERNET_P2P',
  OffnetEthernetP2P = 'OFFNET_ETHERNET_P2P',
  IpVPN = 'IP_VPN',
  OffnetIpVPN = 'OFFNET_IP_VPN',
  MobileAccess = 'MOBILE_ACCESS',
  MobileBackup = 'MOBILE_BACKUP',
}
