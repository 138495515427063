import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { voipSettingsTabs } from 'app/utils/navigation-utils';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { ForwardingCard } from '../components/forwarding-card';
import { NumberCard } from '../components/number-card';
import { useVoipSettings } from '../voip-common';
import { RejectionCard } from '../components/rejection-card';
import { VoipSettingsTitle } from '../components/voip-settings-title';
import { useEffect } from 'react';
import { VoipContainer } from './container';
import styles from '../voip-common.module.scss';

export const VoipSettingsPage: ActiveCustomerLocationPage = ({ userContext }) => {
  const {
    locations: { data: locationData },
  } = useAppSelector((state) => state.voipSettings);
  const { t } = useTranslation();
  const { settings, fetchData } = useVoipSettings();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <VoipContainer services={userContext.services}>
      <div className={styles.container}>
        <ContainerFixed className={styles.tabbedContainer}>
          <VoipSettingsTitle />
          <TabbedContent selectedTabKey={'overview'} tabList={voipSettingsTabs}>
            <ContainerFixed isNarrow={true} className={styles.section}>
              <Typography
                component="h3"
                variant="formds-altibox-subtitle-secondary"
                className={styles.header}
                bold={true}
              >
                {t('pages.voip.overview.myNumbers')}
              </Typography>
              <ul>
                {locationData?.map((location) => (
                  <li key={location.address}>
                    <NumberCard location={location} />
                  </li>
                ))}
              </ul>
            </ContainerFixed>

            <ContainerFixed isNarrow={true} className={styles.section}>
              <Typography
                component="h3"
                variant="formds-altibox-subtitle-secondary"
                className={styles.header}
                bold={true}
              >
                {t('pages.voip.forwarding.name')}
              </Typography>
              <ul>
                {locationData?.map((location) =>
                  location.phoneNumbers?.map((phone) => {
                    return (
                      <li key={phone + location.address + location.postalCode}>
                        <ForwardingCard location={location} phone={phone} settings={settings} />
                      </li>
                    );
                  }),
                )}
              </ul>
            </ContainerFixed>

            <ContainerFixed isNarrow={true} className={styles.section}>
              <Typography
                component="h3"
                variant="formds-altibox-subtitle-secondary"
                className={styles.header}
                bold={true}
              >
                {t('pages.voip.rejection.name')}
              </Typography>

              {locationData?.map((location) =>
                location.phoneNumbers?.map((phone) => {
                  return (
                    <RejectionCard
                      location={location}
                      settings={settings}
                      phone={phone}
                      key={phone + location.address}
                    />
                  );
                }),
              )}
            </ContainerFixed>
          </TabbedContent>
        </ContainerFixed>
      </div>
    </VoipContainer>
  );
};
