import { createAsyncThunk } from '@reduxjs/toolkit';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';
import { setPdfErrorCode, setPostponeRejectionCode } from './invoice-actions';

interface InvoiceParams {
  from: string;
  to: string;
}

export const getInvoices = createAsyncThunk<MinesiderBackend.Invoice[], InvoiceParams>(
  'invoice/getInvoices',
  async (params, thunkApi) => {
    const invoices = minesiderBackendService.getInvoices(params.from, params.to);
    invoices
      .then((result) => result)
      .catch(() => {
        thunkApi.dispatch(
          addToastMessage({
            heading: i18n.t('pages.invoice.errormessages.invoiceFetchFailed'),
            alertType: 'warning',
            role: 'alert',
          }),
        );
      });
    return invoices;
  },
);

export const getMoreInvoices = createAsyncThunk<MinesiderBackend.Invoice[], InvoiceParams>(
  'invoice/getMoreInvoices',
  async (params, thunkApi) => {
    const invoices = minesiderBackendService.getInvoices(params.from, params.to);
    invoices
      .then((result) => result)
      .catch(() => {
        thunkApi.dispatch(
          addToastMessage({
            heading: i18n.t('pages.invoice.errormessages.invoiceFetchFailed'),
            alertType: 'warning',
            role: 'alert',
          }),
        );
      });
    return invoices;
  },
);

export const getInvoicePaymentInformation = createAsyncThunk<MinesiderBackend.PaymentInformation>(
  'invoice/getPaymentInformation',
  async (_, thunkApi) => {
    const paymentInfo = minesiderBackendService.getInvoicePaymentInformation();
    paymentInfo
      .then((result) => result)
      .catch(() => {
        thunkApi.dispatch(
          addToastMessage({
            heading: i18n.t('pages.invoice.errormessages.paymentInfoFetchFailed'),
            alertType: 'warning',
            role: 'alert',
          }),
        );
      });
    return paymentInfo;
  },
);

export const getInvoicePaymentAgreementParameters = createAsyncThunk<MinesiderBackend.PaymentAgreement>(
  'invoice/getInvoicePaymentUrl',
  async (_, thunkApi) => {
    const paymentUrl = minesiderBackendService.getInvoicePaymentAgreementParameters('no'); // TODO: countryCode
    paymentUrl
      .then((result) => result)
      .catch(() => {
        thunkApi.dispatch(
          addToastMessage({
            heading: i18n.t('pages.invoice.invoiceAvtaleGiro.errormessages.paymentUrlFetchFailed'),
            alertType: 'warning',
            role: 'alert',
          }),
        );
      });
    return paymentUrl;
  },
);

export const getInvoiceLines = createAsyncThunk<MinesiderBackend.InvoiceUsage[], string>(
  'invoice/getInvoiceLines',
  async (invoiceId, thunkApi) => {
    const invoiceLines = minesiderBackendService.getInvoiceLines(invoiceId);
    invoiceLines
      .then((result) => result)
      .catch(() => {
        thunkApi.dispatch(
          addToastMessage({
            heading: i18n.t('pages.invoiceDetails.invoiceLines.errormessages.fetchFailed'),
            alertType: 'warning',
            role: 'alert',
          }),
        );
      });
    return invoiceLines;
  },
);

interface PostponeParams {
  invoiceId: string;
}

export const postponeInvoice = createAsyncThunk<MinesiderBackend.InvoicePostpone, PostponeParams>(
  'invoice/postponeInvoice',
  async (params, thunkApi) => {
    const postponeState = minesiderBackendService.postponeInvoice(params.invoiceId);
    postponeState
      .then((result) => {
        thunkApi.dispatch(
          addToastMessage({
            heading: i18n.t('pages.invoice.invoicePostpone.confirmed.name'),
            alertType: 'success',
            role: 'status',
          }),
        );
        return result;
      })
      .catch((error) => {
        thunkApi.dispatch(setPostponeRejectionCode(error.data.errorCode));
        thunkApi.dispatch(
          addToastMessage({
            heading: i18n.t('pages.invoice.errormessages.postponeRequestFailed'),
            alertType: 'warning',
            role: 'alert',
          }),
        );
      });
    return postponeState;
  },
);

interface PdfParams {
  invoiceId: string;
  customerCrmId: string;
}

export const getInvoicePdf = createAsyncThunk<ArrayBuffer, PdfParams>(
  'invoice/getPdf',
  async ({ invoiceId, customerCrmId }, thunkApi) => {
    const invoicePdfResponse = minesiderBackendService.getInvoicePdf(invoiceId, customerCrmId);
    invoicePdfResponse
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoiceId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        const errorCode = error.data?.errorCode;
        if (errorCode) {
          thunkApi.dispatch(setPdfErrorCode(errorCode));
        }
        thunkApi.dispatch(
          addToastMessage({
            heading: errorCode
              ? i18n.t(`pages.invoice.invoiceErrors.DYNAMIC_KEYS.${errorCode}`)
              : i18n.t('pages.invoice.errormessages.pdfLinkFailed'),
            alertType: 'warning',
            role: 'alert',
          }),
        );
      });
    return invoicePdfResponse;
  },
);
