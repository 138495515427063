import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { voipSettingsTabs } from 'app/utils/navigation-utils';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { ForwardingCard } from '../components/forwarding-card';
import { useVoipSettings } from '../voip-common';
import { DeleteRuleConfirmation, VoipRuleType } from '../components/delete-rule-confirm';
import { NumberDropDown } from '../components/number-dropdown';
import { VoipSettingsTitle } from '../components/voip-settings-title';
import { ErrorTypes, FriendlyErrorMessage } from 'app/store/root-types';
import { Service } from 'app/service/navigation/pages/page-types';
import { raiseGlobalError } from 'app/store/actions/core-actions';
import { useEffect } from 'react';
import { VoipContainer } from './container';
import styles from '../voip-common.module.scss';

export const VoipForwarding: ActiveCustomerLocationPage = ({ userContext }) => {
  const {
    locations: { data: locationData, fetchStatus: locationFetchStatus },
    updateSettings: { deleteRule },
  } = useAppSelector((state) => state.voipSettings);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { settings, selectedPhoneNumber, setSelectedSettings, fetchData } = useVoipSettings();

  useEffect(() => {
    fetchData();
  }, []);

  if (!userContext.services.includes(Service.Voip || Service.TaVoip)) {
    dispatch(
      raiseGlobalError({
        message: FriendlyErrorMessage.NO_SERVICES_FOR_LOCATION,
        type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
      }),
    );
  }

  function selectNumber(phone: string) {
    setSelectedSettings(phone);
  }

  if (!selectedPhoneNumber) {
    return null;
  }

  if (deleteRule !== undefined) {
    return (
      <DeleteRuleConfirmation
        voipRuleType={VoipRuleType.FORWARD}
        phoneNumber={selectedPhoneNumber}
        ruleAppId={deleteRule}
      />
    );
  }

  return (
    <VoipContainer services={userContext.services}>
      <div className={styles.container}>
        <ContainerFixed className={styles.tabbedContainer}>
          <VoipSettingsTitle />

          <TabbedContent selectedTabKey={'forwarding'} tabList={voipSettingsTabs}>
            <ContainerFixed isNarrow={true} className={styles.section}>
              <NumberDropDown
                selectNumber={selectNumber}
                selectedPhoneNumber={selectedPhoneNumber}
                className={styles.chooseNumber}
              />

              {settings[selectedPhoneNumber] && (
                <Typography
                  component="h3"
                  variant="formds-altibox-subtitle-secondary"
                  className={styles.header}
                  bold={true}
                >
                  {t('pages.voip.forwarding.name')}
                </Typography>
              )}
              {locationData?.map((location) =>
                location.phoneNumbers
                  ?.filter((phone) => phone === selectedPhoneNumber)
                  .map((phone) => {
                    return (
                      <ForwardingCard
                        isEditable={true}
                        location={location}
                        key={phone}
                        phone={phone}
                        settings={settings}
                      />
                    );
                  }),
              )}
            </ContainerFixed>
          </TabbedContent>
        </ContainerFixed>
      </div>
    </VoipContainer>
  );
};
