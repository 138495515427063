import { FdsButton, FdsLink, FdsNavListItem, FdsNavListItems } from '@lyse-as/formds-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breakpoint, Typography as T } from 'app/component-library-wave';
import { ContainerFixed } from 'app/components/container';
import { CustomerPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { getInvoicePaymentInformation, getInvoices, getMoreInvoices } from 'app/store/actions/invoice-thunks';
import { FetchStatus } from 'app/store/root-types';
import { Spinner } from 'app/components/spinner';

import { ContainerLayoutMode } from 'app/components/container/container';
import { navigationService } from 'app/service/navigation/navigation-service';
import { iso8601ToFullMonthAndYear, iso8601ToShortDate } from 'app/utils/date-utils';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { InvoiceOverview } from './invoice-overview/invoice-overview';
import { settlementIconStates, SettlementState } from './settlement-state/settlement-state';
import { setLoadedInvoicesFrom } from 'app/store/actions/invoice-actions';
import { useDownloadInvoicePdf } from './invoice-utils';
import { InvoiceCustomerServiceLink } from './invoice-customerservice-link/invoice-customerservice-link';
import {
  getGtmFullUrl,
  GtmDataLayer,
  GtmElementCategory,
  GtmElementCta,
  pushDataLayer,
  UserActionScenario,
} from 'app/utils/gtm-tracking-utils';

import styles from './invoice.module.scss';

export const InvoicePage: CustomerPage = ({ userContext }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchStatus, invoices, paymentInformation, loadedInvoicesFrom } = useAppSelector((state) => state.invoice);
  const { downloadPdf, downloadFetchStatus } = useDownloadInvoicePdf();
  const [latestInvoice, setLatestInvoice] = useState<MinesiderBackend.Invoice>();
  const { goToPath } = useAppNavigation();

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      const today = new Date();
      const fromDate = today.setMonth(today.getMonth() - 8); // Unix time in ms
      const fromDateFormatted = new Date(fromDate).toISOString().split('T')[0]; // 'yyyy-mm-dd'
      const to = '2100-01-01';

      dispatch(setLoadedInvoicesFrom(fromDate));
      dispatch(getInvoices({ from: fromDateFormatted, to }));
      dispatch(getInvoicePaymentInformation());
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (invoices) {
      setLatestInvoice(invoices[0]);
    }
  }, [invoices]);

  function loadMoreInvoices() {
    const prevFromDate = new Date(loadedInvoicesFrom || Date.now());
    const newTo = new Date(loadedInvoicesFrom || Date.now()).toISOString().split('T')[0]; // 'yyyy-mm-dd'

    const newFrom = prevFromDate.setMonth(prevFromDate.getMonth() - 12);
    const newFromFormatted = new Date(newFrom).toISOString().split('T')[0]; // 'yyyy-mm-dd'
    dispatch(setLoadedInvoicesFrom(newFrom));

    dispatch(getMoreInvoices({ from: newFromFormatted, to: newTo }));

    const dataLayer: GtmDataLayer = {
      element_cta: GtmElementCta.FAKTURA_LAST_ELDRE_FAKTURAER,
      element_category: GtmElementCategory.INVOICE_OVERVIEW,
      element_status: latestInvoice?.settlementState,
      element_description: iso8601ToFullMonthAndYear(latestInvoice?.billDate || ''),
    };
    pushDataLayer(UserActionScenario.INVOICE_LOAD_MORE_INVOICES_CLICKED, userContext, dataLayer);
  }

  const getLoadedInvoicesFromMessage = () => {
    const loadedFromDate = new Date(loadedInvoicesFrom || Date.now()).toISOString();
    const formattedLoadedFromDate = iso8601ToShortDate(loadedFromDate);
    return t('pages.invoice.loadedInvoicesFromDateMessage', {
      date: formattedLoadedFromDate,
    });
  };

  const seeInvoiceDetailsClicked = (latestInvoice: MinesiderBackend.Invoice) => {
    const fullUrl = getGtmFullUrl(`${navigationService.PAGES.invoiceDetails.url}?invoiceId=:invoiceId`);

    pushDataLayer(UserActionScenario.INVOICE_SEE_DETAILS_CLICKED, userContext, {
      element_status: latestInvoice.settlementState,
      element_description: iso8601ToFullMonthAndYear(latestInvoice.billDate || ''),
      element_category: GtmElementCategory.INVOICE_OVERVIEW,
      element_cta: GtmElementCta.FAKTURA_SE_FAKTURADETALJER,
      element_url: fullUrl,
    });
  };

  const createAvtaleGiroClicked = () => {
    const dataLayer: GtmDataLayer = {
      element_category: GtmElementCategory.INVOICE_OVERVIEW,
      element_cta: GtmElementCta.FAKTURA_CREATE_AVTALEGIRO_LINK,
      element_status: latestInvoice?.settlementState,
      element_description: iso8601ToFullMonthAndYear(latestInvoice?.billDate || ''),
      element_url: getGtmFullUrl(navigationService.PAGES.invoiceAvtaleGiro.url),
    };
    pushDataLayer(UserActionScenario.INVOICE_CREATE_AVTALEGIRO_LINK_CLICKED, userContext, dataLayer);
  };

  const invoiceListItemClicked = (invoice: MinesiderBackend.Invoice) => {
    const dataLayer: GtmDataLayer = {
      element_category: GtmElementCategory.INVOICE_OVERVIEW,
      element_cta: iso8601ToFullMonthAndYear(invoice?.billDate || ''),
      element_status: latestInvoice?.settlementState,
      element_description: iso8601ToFullMonthAndYear(latestInvoice?.billDate || ''),
      element_url: getGtmFullUrl(`${navigationService.PAGES.invoiceDetails.url}?invoiceId=:invoiceId`),
    };
    pushDataLayer(UserActionScenario.INVOICE_LIST_ITEM_CLICKED, userContext, dataLayer);
  };

  const postponeInvoiceClicked = (latestInvoice: MinesiderBackend.Invoice) => {
    const postponeUrl = navigationService.getPostponeUrl(latestInvoice.id!);
    const dataLayer: GtmDataLayer = {
      element_category: GtmElementCategory.INVOICE_OVERVIEW,
      element_cta: GtmElementCta.FAKTURA_UTSETT_FAKTURA_LINK,
      element_status: latestInvoice?.settlementState,
      element_description: iso8601ToFullMonthAndYear(latestInvoice?.billDate || ''),
      element_url: getGtmFullUrl(navigationService.PAGES.invoicePostpone.url),
    };
    pushDataLayer(UserActionScenario.INVOICE_POSTPONE_LINK_CLICKED, userContext, dataLayer);

    goToPath(postponeUrl);
  };

  return (
    <ContainerFixed mode={ContainerLayoutMode.Medium} className={styles.pageContainer}>
      <T
        variant="formds-altibox-header-tertiary"
        component="h1"
        maxBreakpoint={Breakpoint.TABLET}
        bold={true}
        className={styles.pageHeading}
      >
        {t('pages.invoice.name')}
      </T>
      <T variant="formds-body" component="p" className={styles.ingress}>
        {t('pages.invoice.ingress')}
      </T>

      <T variant="formds-lead" component="h2" bold={true} className={styles.invoiceLeadHeading}>
        {t('pages.invoice.latestInvoice')}
      </T>

      <section className={styles.borderContainer}>
        {fetchStatus === FetchStatus.PENDING && !latestInvoice && <Spinner />}
        {latestInvoice && (
          <>
            <InvoiceOverview
              invoice={latestInvoice}
              paymentInformation={paymentInformation}
              elementCategory={GtmElementCategory.INVOICE_OVERVIEW}
            />
            <div className={styles.detailsButtonContainer}>
              <FdsButton
                variant="primary"
                iconRight="launch"
                fullWidth={true}
                onClick={() => downloadPdf(latestInvoice, GtmElementCategory.INVOICE_OVERVIEW)}
                isLoading={downloadFetchStatus === FetchStatus.PENDING}
              >
                {t('pages.invoice.downloadInvoice')}
              </FdsButton>
              <FdsButton fullWidth={true} variant="secondary" onClick={() => postponeInvoiceClicked(latestInvoice)}>
                {t('pages.invoice.postponeInvoice')}
              </FdsButton>
              <FdsLink
                icon="arrow_forward"
                className={styles.detailsLink}
                href={`${navigationService.PAGES.invoiceDetails.url}?invoiceId=${latestInvoice.id}`}
                preventReload={true}
                onClick={() => seeInvoiceDetailsClicked(latestInvoice)}
              >
                {t('pages.invoice.seeInvoiceDetails')}
              </FdsLink>
            </div>
          </>
        )}
        {!latestInvoice && fetchStatus !== FetchStatus.PENDING && (
          <T variant="formds-body" component="p">
            {t('pages.invoice.noRecentInvoices')}
          </T>
        )}
      </section>

      <section>
        <T variant="formds-lead" component="h2" bold={true}>
          {t('pages.invoice.previousInvoices')}
        </T>
        <T variant="formds-body" component="p" className={styles.loadedInvoicesFromMessage} aria-live="polite">
          {getLoadedInvoicesFromMessage()}
        </T>
        <div className={styles.borderContainer}>
          {invoices.length <= 1 && fetchStatus === FetchStatus.FULFILLED ? (
            <T variant="formds-body" component="p">
              {t('pages.invoice.noInvoicesInSelectedDateRange')}
            </T>
          ) : (
            <FdsNavListItems className={styles.previousInvoicesList}>
              {invoices
                .filter((invoice) => invoice.id !== latestInvoice?.id)
                .map((invoice) => {
                  const { iconName, i18nKey } = settlementIconStates({
                    settlementState: invoice.settlementState as SettlementState,
                    dueDate: invoice.dueDate,
                  });

                  return (
                    <FdsNavListItem
                      header={iso8601ToFullMonthAndYear(invoice.billDate || '')}
                      href={`${navigationService.PAGES.invoiceDetails.url}?invoiceId=${invoice.id}`}
                      description={t(i18nKey)}
                      headerIcon={iconName}
                      navText={`${invoice.taxIncludedSum} kr`}
                      navIcon="chevron_right"
                      key={invoice.id}
                      preventReload={true}
                      onClick={() => invoiceListItemClicked(invoice)}
                    />
                  );
                })}
            </FdsNavListItems>
          )}
        </div>

        <div className={styles.centerContainer}>
          <FdsButton
            className={styles.loadMoreInvoices}
            onClick={loadMoreInvoices}
            isLoading={fetchStatus === FetchStatus.PENDING}
            loadingText={t('pages.invoice.loadingInvoices')}
            variant="primary"
          >
            {t('pages.invoice.loadOlderInvoices')}
          </FdsButton>
        </div>
      </section>

      {paymentInformation?.paymentMethod !== 'AVTALEGIRO' && (
        <section className={styles.avtaleGiro}>
          <T variant="formds-lead" component="h2" bold={true} className={styles.invoicingQuestionsHeader}>
            {t('pages.invoice.avtaleGiro')}
          </T>
          <T component="p" className={styles.invoicingQuestionsHeader} variant="formds-body">
            {t('pages.invoice.avtaleGiroDescription')}
          </T>
          <FdsLink
            href={navigationService.PAGES.invoiceAvtaleGiro.url}
            icon="launch"
            preventReload={true}
            onClick={createAvtaleGiroClicked}
          >
            {t('pages.invoice.createAvtaleGiro')}
          </FdsLink>
        </section>
      )}

      <div className={styles.pageBottomMargin}>
        <InvoiceCustomerServiceLink />
      </div>
    </ContainerFixed>
  );
};
