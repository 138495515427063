import { createRoot } from 'react-dom/client';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import 'app/i18n/i18n';
import { store, persistor } from 'app/store/configure-store';
import { App } from 'app';
import { HeaderForSuspense } from 'app/features/header';

import './index.scss';
import '@lyse-as/formds-tokens/css/altibox.css';

const container = document.getElementById('root')!;
const root = createRoot(container);
const main = async () => {
  if (process.env.NODE_ENV === 'development') {
    if (['', '/', '/minesider'].includes(window.location.pathname)) {
      window.location.pathname = '/minesider/';
      return;
    }

    // Learn more about Mock Service Worker: https://mswjs.io/docs/
    if (process.env.REACT_APP_MOCK_API === 'true') {
      await import('app/mocks/browser').then(async ({ worker }) => {
        worker.start({
          serviceWorker: {
            url: '/minesider/mockServiceWorker.js',
          },
          onUnhandledRequest: 'bypass',
        });
      });
    }
  }

  root.render(
    <Provider store={store}>
      <BrowserRouter basename="/" future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<HeaderForSuspense />}>
            <App />
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>,
  );
};

main();
