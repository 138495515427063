import React from 'react';

import { Breakpoint, Typography } from 'app/component-library-wave';

import styles from './subscription-group.module.scss';

interface Props {
  name: string;
  children?: React.ReactNode[];
}

export const SubscriptionGroup: React.FC<Props> = ({ name, children }) => (
  <div className={styles.group}>
    <Typography
      variant="headline5"
      component="h2"
      className={styles.heading}
      bold={true}
      maxBreakpoint={Breakpoint.MOBILE}
    >
      {name}
    </Typography>
    <ul className={styles.subscriptionItems}>{children}</ul>
  </div>
);
