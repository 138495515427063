import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FdsButton } from '@lyse-as/formds-react';

import styles from './invoice-avtalegiro.module.scss';
import {
  GtmDataLayer,
  GtmElementCategory,
  GtmElementCta,
  pushDataLayer,
  UserActionScenario,
} from 'app/utils/gtm-tracking-utils';
import { useAppSelector } from 'app/hooks/redux-thunk';

export enum NetsAvtaleType {
  AvtaleGiro = 'avtalegiro',
  eFaktura = 'efaktura',
  AvtaleGiroAndEFaktura = 'combination',
  None = 'none',
}

interface Props {
  paymentAgreement: MinesiderBackend.PaymentAgreement;
  returnUrl: string;
  netsAvtaleType: NetsAvtaleType;
  disableSubmitButton: boolean;
  children?: React.ReactNode;
}

export const NetsAvtaleGiroForm = ({ paymentAgreement, returnUrl, netsAvtaleType, children }: Props) => {
  const { t } = useTranslation();
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState(false);
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  const formSubmitted = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitButtonIsLoading(true);

    const dataLayer: GtmDataLayer = {
      element_category: GtmElementCategory.AVTALEGIRO,
      element_cta: GtmElementCta.FAKTURA_CREATE_AVTALEGIRO_CONTINUE,
      element_url: paymentAgreement.baseUrl,
    };
    pushDataLayer(UserActionScenario.INVOICE_CREATE_AVTALEGIRO_CONTINUE_CLICKED, selectedCustomerLocation, dataLayer);

    const form = e.target as HTMLFormElement;
    form.submit();
  };

  return (
    <form action={paymentAgreement.baseUrl} method="post" onSubmit={formSubmitted}>
      {children}
      <input type="hidden" name="companyName" id="companyName" value={paymentAgreement.companyName} />
      <input type="hidden" name="returnUrl" id="returnUrl" value={returnUrl} />

      {(netsAvtaleType === NetsAvtaleType.AvtaleGiro || netsAvtaleType === NetsAvtaleType.AvtaleGiroAndEFaktura) && (
        <>
          <input
            type="hidden"
            name="companyAccountNo"
            id="companyAccountNo"
            value={paymentAgreement.companyAccountNo}
          />
          <input type="hidden" name="kid" id="kid" value={paymentAgreement.kid} />
        </>
      )}
      {netsAvtaleType === NetsAvtaleType.AvtaleGiro && (
        <FdsButton
          iconRight="launch"
          type="submit"
          className={styles.avtaleGiroButtonContinue}
          isLoading={submitButtonIsLoading}
        >
          {t('pages.invoice.invoiceAvtaleGiro.avtaleGiroButtonContinue')}
        </FdsButton>
      )}
    </form>
  );
};
