import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint, Button, ButtonType, Typography, Alert } from 'app/component-library-wave';
import { FdsAlert } from '@lyse-as/formds-react';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { deleteFirewallRule } from 'app/store/actions/firewall-thunks';
import { fetchNetworkSettings } from 'app/store/actions/network-thunks';
import { FetchStatus } from 'app/store/root-types';
import { navigationService } from 'app/service/navigation/navigation-service';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { internetSettingsTabList, useAppNavigation } from 'app/utils/navigation-utils';
import { FirewallRule } from './firewall-rule';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { HiddenH1 } from 'app/components/hidden-h1';
import { AlertWarning } from 'app/media';

import styles from './firewall-settings.module.scss';

export const FirewallSettingsPage: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();
  const customerId = props.userContext.customerCrmId;
  const servicePointId = props.userContext.servicePointId;
  const { fetchStatus: networkFetchStatus, data: networkData } = useAppSelector((state) => state.network);
  const {
    deleteFirewallRule: { fetchStatus: deleteStatus },
  } = useAppSelector((state) => state.firewall);

  const portForwardings = networkData?.networkSettings?.portForwardings;
  const maxNumberOfRoutes = networkData?.networkSettings?.maxRoutes;
  const noHomeGateway = networkData?.installationType === 'NO_HOMEGATEWAY';
  const hasCgNat = networkData?.hgw?.cgNat;
  const canAddPortForwardings = maxNumberOfRoutes !== undefined && maxNumberOfRoutes > 0 && portForwardings;

  useEffect(() => {
    if (networkFetchStatus === FetchStatus.NOT_STARTED && servicePointId && customerId) {
      dispatch(fetchNetworkSettings({ servicePointId, customerId }));
    }
  }, [networkFetchStatus, servicePointId, customerId]);

  const tabList = internetSettingsTabList({ userContext: props.userContext, networkData });
  const [isAddRuleButtonPressed, showRulesExceededErrorMessage] = useState(false);

  if (!servicePointId || !customerId) {
    return <Spinner />;
  }

  const handleUpdateRule = (rule: MinesiderBackend.PortForwarding) =>
    goToPath(navigationService.getEditFirewallRuleUrl(rule.id));

  const handleDeleteRule = (rule: MinesiderBackend.PortForwarding) =>
    dispatch(deleteFirewallRule({ servicePointId, customerId, rule }));

  const MaxNumberOfRoutesAlert = () => {
    if (!isAddRuleButtonPressed) return null;
    return (
      <>
        <div>
          <AlertWarning className={styles.alertWarningIcon} />
        </div>
        <Typography variant="uiText3" component="span" maxBreakpoint={Breakpoint.TABLET} id="maxNumberOfRoutesHelpText">
          {t('pages.firewall.numberOfRoutesExceededErrorMessage')}
        </Typography>
      </>
    );
  };
  const addRuleHandler = () => {
    if (maxNumberOfRoutes && portForwardings) {
      if (portForwardings.length >= maxNumberOfRoutes) {
        showRulesExceededErrorMessage(true);
        return;
      }
      goToPath(navigationService.PAGES.firewallCreateRule.url);
    }
  };

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1>{t('pages.network.advanced.name')}</HiddenH1>
      <TabbedContent selectedTabKey={'portForwarding'} tabList={tabList}>
        <div className={styles.firewallContainer}>
          {hasCgNat && (
            <FdsAlert variant="info" disableDismiss={true}>
              <h2 slot="alert-heading">{t('pages.firewall.cgNatPortForwardingDisabledHeading')}</h2>
              {t('pages.firewall.cgNatPortForwardingDisabled')}
            </FdsAlert>
          )}
          <section className={styles.section}>
            <ContainerFixed isNarrow={true} className={styles.introductionContainer}>
              <div className={styles.introduction}>
                <Typography
                  variant="headline4"
                  component="h2"
                  className={styles.heading}
                  maxBreakpoint={Breakpoint.TABLET}
                >
                  {t('pages.firewall.introduction.heading')}
                </Typography>
                <Typography variant="uiText3" component="p" className={styles.description}>
                  {t('pages.firewall.introduction.description')}
                </Typography>
                <Typography variant="uiText2" component="p" bold={true}>
                  <a
                    href="/privat/kundeservice/hjelp-til-internett/selvbetjening/brannmur/"
                    className={styles.moreInfoLink}
                  >
                    {t('pages.firewall.introduction.link.name')}
                  </a>
                </Typography>
              </div>
            </ContainerFixed>
            {!noHomeGateway && !hasCgNat && canAddPortForwardings && (
              <div>
                <div className={styles.rulesSummary}>
                  <Typography
                    variant="uiText1"
                    component="h2"
                    className={styles.heading1}
                    maxBreakpoint={Breakpoint.TABLET}
                    bold={true}
                  >
                    {t('pages.firewall.numberOfRoutes', { maxNumberOfRoutes })}
                  </Typography>
                  <Typography
                    variant="uiText1"
                    component="p"
                    className={styles.heading2}
                    maxBreakpoint={Breakpoint.TABLET}
                  >
                    {t('pages.firewall.customersNumberOfRoutes', {
                      numberOfRoutes: portForwardings.length,
                      maxNumberOfRoutes,
                    })}
                  </Typography>
                  <div className={styles.buttonContainer}>
                    <Button
                      buttonType={ButtonType.PRIMARY_B}
                      className={styles.createRuleButton}
                      onClick={addRuleHandler}
                    >
                      {t('pages.firewall.buttons.createFirewallRule')}
                    </Button>
                  </div>
                </div>
                <div className={styles.helpTextContainer}>
                  <div className={styles.rulesExceededAlertContainer}>{MaxNumberOfRoutesAlert()}</div>
                </div>
              </div>
            )}
          </section>
          {networkFetchStatus === FetchStatus.FULFILLED && networkData && noHomeGateway && (
            <Alert
              alertType="warning"
              heading={t('pages.firewall.noHomeGateway')}
              headingElement="strong"
              isExpandable={false}
              role="alert"
            />
          )}
          {(networkFetchStatus === FetchStatus.NOT_STARTED || networkFetchStatus === FetchStatus.PENDING) && (
            <Spinner />
          )}
          {!hasCgNat && portForwardings && (
            <ul className={styles.rulesSection}>
              {portForwardings.map((rule) => (
                <li key={rule.id} className={styles.ruleContainer}>
                  <FirewallRule
                    fetchStatus={deleteStatus}
                    key={rule.name}
                    rule={rule}
                    onUpdateRule={handleUpdateRule}
                    onDeleteRule={handleDeleteRule}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </TabbedContent>
    </ContainerFixed>
  );
};
