import { Typography } from 'app/component-library-wave';
import { FdsButton } from '@lyse-as/formds-react';
import { FetchStatus } from 'app/store/root-types';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { postponeInvoice } from 'app/store/actions/invoice-thunks';
import { useTranslation } from 'react-i18next';
import styles from './invoice-postpone.module.scss';
import {
  GtmDataLayer,
  GtmElementCategory,
  GtmElementCta,
  pushDataLayer,
  UserActionScenario,
} from 'app/utils/gtm-tracking-utils';

interface Props {
  invoiceId: string;
  fetchStatus: FetchStatus;
}

export const ConfirmStep = ({ invoiceId, fetchStatus }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  const handleClick = () => {
    const dataLayer: GtmDataLayer = {
      element_category: GtmElementCategory.INVOICE_POSTPONE,
      element_cta: GtmElementCta.FAKTURA_UTSETT_FAKTURA_BEKREFT,
    };
    pushDataLayer(UserActionScenario.INVOICE_POSTPONE_CONFIRM, selectedCustomerLocation, dataLayer);

    dispatch(postponeInvoice({ invoiceId }));
  };

  const handleBack = () => {
    history.back();
  };

  return (
    <>
      <Typography component="h1" variant="formds-altibox-header-tertiary" bold={true}>
        {t('pages.invoice.invoicePostpone.name')}
      </Typography>
      <div className={styles.messageContainer}>
        <Typography component="p" variant="formds-body">
          {t('pages.invoice.invoicePostpone.rules')}
        </Typography>
      </div>
      <div className={styles.buttonContainer}>
        <FdsButton
          variant="primary"
          disabled={fetchStatus === FetchStatus.PENDING}
          onClick={handleClick}
          fullWidth={true}
        >
          {t('pages.invoice.invoicePostpone.confirm')}
        </FdsButton>
        <FdsButton variant="secondary" onClick={handleBack} fullWidth={true}>
          {t('pages.invoice.invoicePostpone.cancel')}
        </FdsButton>
      </div>
    </>
  );
};
