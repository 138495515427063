import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { useVoipSettings, validatePhoneNumber } from '../voip-common';
import { Card } from 'app/components/card';
import { FdsButton, FdsTextField } from '@lyse-as/formds-react';
import { navigationService } from 'app/service/navigation/navigation-service';
import { formatVoipNumber } from 'app/utils/string-util';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { updateVoipSettings } from 'app/store/actions/voip-settings-thunks';
import { FetchStatus } from 'app/store/root-types';
import { resetVoipUpdateStatus } from 'app/store/actions/voip-settings-actions';
import { VoipContainer } from './container';
import styles from '../voip-common.module.scss';

export const RejectionNewRule: ActiveCustomerLocationPage = ({ userContext }) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const dispatch = useAppDispatch();
  const { fetchStatus: updateFetchStatus } = useAppSelector((state) => state.voipSettings.updateSettings);
  const { selectedPhoneNumber, settings } = useVoipSettings();
  const [, setToggleRenderFlag] = useState(false);
  const fromNumberRef = useRef<HTMLFdsTextFieldElement>(null);
  const [submitSettings, setSubmitSettings] = useState(false);

  useEffect(() => {
    if (isFromNumberValid && submitSettings) {
      submitChanges();
      setSubmitSettings(false);
    }
  }, [submitSettings]);

  useEffect(() => {
    if (updateFetchStatus === FetchStatus.FULFILLED) {
      dispatch(resetVoipUpdateStatus());
      goToPath(navigationService.PAGES.voipSettingsCalls.url);
    }
  }, [updateFetchStatus]);

  const isFromNumberValid = fromNumberRef.current?.value && validatePhoneNumber(fromNumberRef.current.value);

  if (!selectedPhoneNumber) {
    return null;
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (updateFetchStatus !== FetchStatus.PENDING) {
      setSubmitSettings((current) => !current);
    }
  };

  const submitChanges = () => {
    if (!fromNumberRef.current?.value) {
      return;
    }
    const newRejectRule: MinesiderBackend.Reject = {
      type: 'specified',
      enabled: true,
      from: [fromNumberRef.current.value],
    };

    const currentSettings = settings[selectedPhoneNumber].settings;
    const newRejectionRules = currentSettings.incoming?.rejects?.concat(newRejectRule);

    const newSettings = {
      ...currentSettings,
      incoming: {
        ...currentSettings.incoming,
        rejects: newRejectionRules,
      },
    };
    dispatch(updateVoipSettings(newSettings));
  };

  return (
    <VoipContainer services={userContext.services}>
      <div className={styles.container}>
        <ContainerFixed className={styles.tabbedContainer}>
          <form onSubmit={handleSubmit}>
            <ContainerFixed isNarrow={true} className={styles.section}>
              <Typography
                component="h5"
                variant="formds-altibox-subtitle-secondary"
                className={styles.header}
                bold={true}
              >
                {t('pages.voip.rejection.rules.title', { voipNumber: formatVoipNumber(selectedPhoneNumber) })}
              </Typography>
              <Card containerClassName={styles.card} className={styles.card}>
                <FdsTextField
                  label={t('pages.voip.rejection.rejectFromLabel')}
                  name="triggerNumber"
                  required={true}
                  type="tel"
                  ref={fromNumberRef}
                  onBlur={() => setToggleRenderFlag((current) => !current)}
                  variant={isFromNumberValid ? 'primary' : 'error'}
                />
              </Card>

              <div className={styles.buttonRow}>
                <FdsButton variant="secondary" onClick={() => goToPath(navigationService.PAGES.voipSettingsCalls.url)}>
                  {t('pages.voip.forwarding.cta.cancel')}
                </FdsButton>
                <FdsButton
                  variant="primary"
                  type="submit"
                  disabled={updateFetchStatus === FetchStatus.PENDING}
                  isLoading={updateFetchStatus === FetchStatus.PENDING}
                >
                  {t('pages.voip.rejection.cta.create')}
                </FdsButton>
              </div>
            </ContainerFixed>
          </form>
        </ContainerFixed>
      </div>
    </VoipContainer>
  );
};
