import React, { useEffect, useState } from 'react';
import { ParamUtils } from 'app/utils/param-utils';
import { LanguageSwitcher } from 'app/features/language-switcher/language-switcher';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { enableTranslatorMode } from 'app/store/actions/core-actions';

export const TranslatorBar: React.FC = () => {
  const lng = ParamUtils.getQueryParam('lng') || 'nb';
  const lngBar = ParamUtils.getQueryParam('lngBar');
  const translatorMode = useAppSelector((state) => state.core.isTranslatorMode);
  const dispatch = useAppDispatch();

  const [showLanguageBar, setShowLanguageBar] = useState(lngBar !== null);

  useEffect(() => {
    if (showLanguageBar) {
      dispatch(enableTranslatorMode());
    }
  }, []);

  if (showLanguageBar) {
    return <LanguageSwitcher initialLocizeLanguage={lng} setShowLanguageBar={setShowLanguageBar} />;
  }

  if (translatorMode || process.env.NODE_ENV === 'development') {
    return null;
  }
};
