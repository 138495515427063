import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import classNames from 'classnames';
import { Col, Grid, GridWidth, Typography } from 'app/component-library-wave';

import { navigationService } from 'app/service/navigation/navigation-service';
import { useAppSelector } from 'app/hooks/redux-thunk';
import { menuAccount, menuInternet, menuTelephone, menuTv } from 'app/utils/navigation-utils';
import { useContactDetails } from 'app/hooks/use-contact-details';

import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { CustomerType } from 'app/store/types/user-context-types';
import { Spinner } from 'app/components/spinner';
import { FetchStatus } from 'app/store/root-types';
import { EmailInactiveWarning } from 'app/features/email-inactive-warning/email-inactive-warning';
import { OperationalMessages } from 'app/features/operational-messages';
import { FixedWirelessAccessErrorMessage } from '../internet/network/fixed-wireless-access/fixed-wireless-access-error-message';
import { ResendVerificationMessage } from 'app/features/resend-verfication-messages/resend-verification-messages';
import { LocationPicker } from 'app/components/location-picker';
import { Navigation } from 'app/features/navigation';
import { HiddenH1 } from 'app/components/hidden-h1';
import { LogoType, PartnerLogo } from 'app/components/partner-logo/partner-logo';
import { ConsentReminderNotification } from 'app/features/consent-reminder-notification/consent-reminder-notification';

import styles from './front-page.module.scss';

export const Forside: ActiveCustomerLocationPage = ({ userContext }) => {
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedCustomerLocation]);

  const { t } = useTranslation();

  const allLinkSections = [
    {
      title: t('pages.tv.name'),
      pages: menuTv,
    },
    {
      title: t('pages.internet.name'),
      pages: menuInternet,
    },
    {
      title: t('pages.telephone.name'),
      pages: menuTelephone,
    },
    {
      title: t('pages.account.name'),
      pages: menuAccount,
    },
  ];

  const customerLocation = userContext;
  const isEnterpriseUser = customerLocation.customerType === CustomerType.BEDRIFT;

  const personalizedSections = customerLocation
    ? navigationService.getPersonalizedLinkSections(allLinkSections, customerLocation)
    : [];

  const containerClasses = classNames(styles.container, personalizedSections.length < 3 ? styles.twoColumns : null);
  const { fetchStatus, contactDetails } = useContactDetails(userContext);

  if (isEnterpriseUser) {
    if (!contactDetails && fetchStatus === FetchStatus.PENDING) {
      return <Spinner />;
    }
  }

  return (
    <>
      <ConsentReminderNotification />
      <div className={styles.welcome}>
        <HiddenH1>{t(navigationService.PAGES.home.i18n)}</HiddenH1>
        <Typography data-hj-suppress={true} component="p" variant="headline4" className={styles.greeting}>
          {t(isEnterpriseUser ? 'pages.home.enterprise.greeting' : 'pages.home.Hei, {{name}}!', {
            name: customerLocation?.firstName,
          })}
        </Typography>
        <Typography component="p" variant="paragraph1" className={styles.text}>
          {isEnterpriseUser
            ? contactDetails?.organizationOwner?.tradingName
            : t('pages.home.Her kan du administrere dine Altibox-tjenester', {
                partnerName: customerLocation?.partnerName,
              })}
        </Typography>
        {!isEnterpriseUser && <PartnerLogo logoType={LogoType.LOGO} className={styles.logo} />}
      </div>
      <Grid width={GridWidth.NARROW}>
        <Col sm={2} md={6} lg={12}>
          {customerLocation ? (
            <LocationPicker selectedLocation={customerLocation} className={styles.locationContainer} />
          ) : null}
        </Col>
      </Grid>
      <Grid width={GridWidth.WIDE}>
        <Col sm={2} md={6} lg={12}>
          <FixedWirelessAccessErrorMessage userContext={userContext} />
          <ResendVerificationMessage />
          <OperationalMessages />
          {userContext.customerType !== CustomerType.BEDRIFT && <EmailInactiveWarning showlinkToEmailPage={true} />}
          <div className={containerClasses}>
            {personalizedSections.map((section) => (
              <div className={styles.menu} key={`listkey-${section.title}`}>
                <Navigation section={section} />
              </div>
            ))}
          </div>
        </Col>
      </Grid>
    </>
  );
};
