import { Forside } from 'app/pages/front-page/front-page';
import { Tv } from 'app/pages/tv/tv';
import { Internett } from 'app/pages/internet/internet';
import { Account } from 'app/pages/account/account';
import { Consent } from 'app/pages/account/privacy-and-concents/consent/consent';
import { ContactDetailsEdit } from 'app/pages/account/contact-details/contact-details-edit/contact-details-edit';
import { ContactDetails } from 'app/pages/account/contact-details/contact-details';
import { ChangePin } from 'app/pages/account/pin/change-pin';
import { ReLocation } from 'app/pages/relocation/relocation';
import { navigationService } from 'app/service/navigation/navigation-service';
import { NewsAndOffers } from 'app/pages/account/news-and-offers/news-and-offers';
import { StreamingServices } from 'app/pages/tv/streaming/streaming-services';
import { StreamingService } from 'app/pages/tv/streaming/streaming-service/streaming-service';
import {
  ActiveCustomerLocationPage,
  InactiveCustomerLocationPage,
  NewCustomerLocationPage,
} from 'app/pages/page-wrapper';
import { DeliveryStatus } from 'app/pages/delivery-status/delivery-status';
import { InactiveAccount, InactiveFrontPage, InactiveInternett, InactiveTv } from 'app/pages/inactive';
import { Email } from 'app/pages/email/email';
import { CreateNewEmail } from 'app/pages/email/create-new-email/create-new-email';
import { ChangePassword } from 'app/pages/email/change-password/change-password';
import { ChangeSenderName } from 'app/pages/email/change-sender-name/change-sender-name';
import { DeleteEmailAccount } from 'app/pages/email/delete-email-account/delete-email-account';
import { ActivateEmailAccount } from 'app/pages/email/activate-email-account/activate-email-account';
import { Products } from 'app/pages/account/products/products';
import { CreateFirewallRulePage } from 'app/pages/internet/network/firewall-settings/create-firewall-rule-page';
import { UpdateFirewallRulePage } from 'app/pages/internet/network/firewall-settings/update-firewall-rule-page';
import { UsersPage } from 'app/pages/user/users-page';
import { UserEditPage } from 'app/pages/user/user-edit-page';
import { UserCreatePage } from 'app/pages/user/user-create-page';
import { UserDeletePage } from 'app/pages/user/user-delete-page';
import { MovieRentalHistory } from 'app/pages/tv/movie-rental-history/movie-rental-history';
import { TelephonePage } from 'app/pages/telephone/telephone';
import { VoipUsage } from 'app/pages/internet/voip-usage/voip-usage';
import { InternetSettings } from 'app/pages/internet/network/internet-settings/internet-settings';
import { NetworkSettings } from 'app/pages/internet/network/network-settings/network-settings';
import { FirewallSettingsPage } from 'app/pages/internet/network/firewall-settings/firewall-settings-page';
import { BridgeModeConfirmation } from 'app/pages/internet/network/wifi-settings/bridge-mode-confirmation/bridge-mode-confirmation';
import { WifiAdvanced } from 'app/pages/internet/network/wifi-settings/wifi-advanced/wifi-advanced';
import { SsidCombineBandConfirmation } from 'app/pages/internet/network/wifi-settings/ssid-combine-band-confirmation/ssid-combine-band-confirmation';
import { privacyAndConsents } from 'app/pages/account/privacy-and-concents/privacy-and-concents';
import { Reservations } from 'app/pages/account/privacy-and-concents/reservations/reservations';
import { VoipSettingsPage } from 'app/pages/account/voip-settings/tabs/overview';
import { VoipForwarding } from 'app/pages/account/voip-settings/tabs/forwarding';
import { ForwardingNewRule } from 'app/pages/account/voip-settings/tabs/forwarding-new-rule';
import { VoipCalls } from 'app/pages/account/voip-settings/tabs/calls';
import { RejectionNewRule } from 'app/pages/account/voip-settings/tabs/rejection-new-rule';
import { VoipBarring } from 'app/pages/account/voip-settings/tabs/barring';
import { InvoicePage } from 'app/pages/account/invoice/invoice';
import { InvoiceDetailsPage } from 'app/pages/account/invoice/invoice-details/invoice-details';
import { InvoicePostpone } from 'app/pages/account/invoice/invoice-postpone/invoice-postpone';
import { InvoiceAvtaleGiro } from 'app/pages/account/invoice/invoice-avtalegiro/invoice-avtalegiro';
import { InvoiceAvtaleGiroReceipt } from 'app/pages/account/invoice/invoice-avtalegiro-receipt/invoice-avtalegiro-receipt';

export interface RoutablePage {
  i18n: string;
  url: string;
  component: React.FC;
}

export interface RoutableActiveCustomerLocationPage {
  i18n: string;
  url: string;
  component: ActiveCustomerLocationPage;
}

export interface RoutableNewCustomerLocationPage {
  i18n: string;
  url: string;
  component: NewCustomerLocationPage;
}

export interface RoutableInactiveCustomerLocationPage {
  i18n: string;
  url: string;
  component: InactiveCustomerLocationPage;
}

export interface Redirect {
  oldUrl: string;
  newUrl: string;
}

class Pages {
  public readonly sharedPages = [
    {
      ...navigationService.PAGES.emailChangePassword,
      component: ChangePassword,
    },
    {
      ...navigationService.PAGES.emailChangeSenderName,
      component: ChangeSenderName,
    },
    {
      ...navigationService.PAGES.emailDeleteAccount,
      component: DeleteEmailAccount,
    },
    {
      ...navigationService.PAGES.emailActivateAccount,
      component: ActivateEmailAccount,
    },
    {
      ...navigationService.PAGES.emailCreateNew,
      component: CreateNewEmail,
    },
    {
      ...navigationService.PAGES.email,
      component: Email,
    },
    {
      ...navigationService.PAGES.movieRentalHistory,
      component: MovieRentalHistory,
    },
    {
      ...navigationService.PAGES.voipUsage,
      component: VoipUsage,
    },
    {
      ...navigationService.PAGES.voipSettingsOverview,
      component: VoipSettingsPage,
    },
    {
      ...navigationService.PAGES.voipSettingsForwarding,
      component: VoipForwarding,
    },
    {
      ...navigationService.PAGES.voipSettingsForwardingNewRule,
      component: ForwardingNewRule,
    },
    {
      ...navigationService.PAGES.voipSettingsRejectionNewRule,
      component: RejectionNewRule,
    },
    {
      ...navigationService.PAGES.voipSettingsCalls,
      component: VoipCalls,
    },
    {
      ...navigationService.PAGES.voipSettingsBarring,
      component: VoipBarring,
    },
    {
      ...navigationService.PAGES.users,
      component: UsersPage,
    },
    {
      ...navigationService.PAGES.userEdit,
      component: UserEditPage,
    },
    {
      ...navigationService.PAGES.userCreate,
      component: UserCreatePage,
    },
    {
      ...navigationService.PAGES.userDelete,
      component: UserDeletePage,
    },
    {
      ...navigationService.PAGES.privacyAndConsents,
      component: privacyAndConsents,
    },
    {
      ...navigationService.PAGES.reservations,
      component: Reservations,
    },
    {
      ...navigationService.PAGES.generalMarketing,
      component: Consent,
    },
    {
      ...navigationService.PAGES.invoice,
      component: InvoicePage,
    },
    {
      ...navigationService.PAGES.invoiceDetails,
      component: InvoiceDetailsPage,
    },
    {
      ...navigationService.PAGES.invoicePostpone,
      component: InvoicePostpone,
    },
    {
      ...navigationService.PAGES.invoiceAvtaleGiro,
      component: InvoiceAvtaleGiro,
    },
    {
      ...navigationService.PAGES.invoiceAvtaleGiroReceipt,
      component: InvoiceAvtaleGiroReceipt,
    },
  ];

  public readonly activePages: RoutableActiveCustomerLocationPage[] = [
    {
      ...navigationService.PAGES.home,
      component: Forside,
    },
    {
      ...navigationService.PAGES.tv,
      component: Tv,
    },
    {
      ...navigationService.PAGES.internet,
      component: Internett,
    },
    {
      ...navigationService.PAGES.firewallCreateRule,
      component: CreateFirewallRulePage,
    },
    {
      ...navigationService.PAGES.firewallUpdateRule,
      component: UpdateFirewallRulePage,
    },
    {
      ...navigationService.PAGES.account,
      component: Account,
    },
    {
      ...navigationService.PAGES.contactDetails,
      component: ContactDetails,
    },
    {
      ...navigationService.PAGES.contactDetailsEdit,
      component: ContactDetailsEdit,
    },
    {
      ...navigationService.PAGES.changePin,
      component: ChangePin,
    },
    {
      ...navigationService.PAGES.relocation,
      component: ReLocation,
    },
    {
      ...navigationService.PAGES.newsAndOffers,
      component: NewsAndOffers,
    },
    {
      ...navigationService.PAGES.myProducts,
      component: Products,
    },
    {
      ...navigationService.PAGES.streamingServices,
      component: StreamingServices,
    },
    {
      ...navigationService.PAGES.streamingService,
      component: StreamingService,
    },
    {
      ...navigationService.PAGES.telephone,
      component: TelephonePage,
    },
    {
      ...navigationService.PAGES.internetSettingsWifiBand,
      component: SsidCombineBandConfirmation,
    },
    {
      ...navigationService.PAGES.internetSettingsWifiAdvanced,
      component: WifiAdvanced,
    },
    {
      ...navigationService.PAGES.internetSettingsOverview,
      component: InternetSettings,
    },
    {
      ...navigationService.PAGES.enterpriseGuestWifi,
      component: InternetSettings,
    },
    {
      ...navigationService.PAGES.internetSettingsNetworkSettings,
      component: NetworkSettings,
    },
    {
      ...navigationService.PAGES.internetSettingsWifiBridgeMode,
      component: BridgeModeConfirmation,
    },
    {
      ...navigationService.PAGES.internetSettingsPortForwarding,
      component: FirewallSettingsPage,
    },
  ];

  public readonly newPages: RoutableNewCustomerLocationPage[] = [
    {
      ...navigationService.PAGES.deliveryStatus,
      component: DeliveryStatus,
    },
  ];

  public readonly inactivePages: RoutableInactiveCustomerLocationPage[] = [
    {
      ...navigationService.PAGES.home,
      component: InactiveFrontPage,
    },
    {
      ...navigationService.PAGES.tv,
      component: InactiveTv,
    },
    {
      ...navigationService.PAGES.internet,
      component: InactiveInternett,
    },
    {
      ...navigationService.PAGES.account,
      component: InactiveAccount,
    },
  ];

  public readonly redirects: Redirect[] = [
    {
      oldUrl: '/minesider/internett/innstillinger/wifi',
      newUrl: '/minesider/internett/innstillinger/oversikt',
    },
  ];
}

const pages = new Pages();

export { pages };
