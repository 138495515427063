import { createAction, Dispatch } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import { GlobalError } from 'app/store/root-types';
import { INFORMATION_PROMPT_GRACE_TIME } from 'app/utils/cookies';

// Types
// Action creators
export const setGlobalError = createAction<GlobalError>('SET_GLOBAL_ERROR');
export const clearGlobalError = createAction<undefined>('CLEAR_GLOBAL_ERROR');
export const enableTranslatorMode = createAction<undefined>('ENABLE_TRANSLATOR_MODE');
export const dismissVerificationPrompt = createAction('DISMISS_VERIFICATION_PROMPT', () => {
  const GRACE_PERIOD = 30 * 86400000; // 30 days
  Cookies.set(INFORMATION_PROMPT_GRACE_TIME, (Date.now() + GRACE_PERIOD).toString());
  return { payload: null };
});

// Thunks
export const raiseGlobalError = (globalError: GlobalError) => async (dispatch: Dispatch) => {
  Sentry.captureException(globalError);

  dispatch(
    setGlobalError({
      type: globalError.type, // consider checking "error instanceof NonRetryableSystemError" when introducing new error types
      message: globalError.message,
      error: globalError.error,
    }),
  );
};
