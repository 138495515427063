import { createSlice, Slice } from '@reduxjs/toolkit';

import { FetchStatus, PinLifecycleState, PinState } from 'app/store/root-types';
import { pinChanged, setCurrentPinState } from 'app/store/actions/pin-actions';
import { logout } from '../actions/auth-thunks';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { getPin, setPin } from '../actions/pin-thunks';

const initialState: PinState = {
  fetchStatus: FetchStatus.NOT_STARTED,
  currentState: PinLifecycleState.INITIAL,
  localPinChange: '',
};

const pinSlice: Slice<PinState> = createSlice({
  name: 'pin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPin.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getPin.fulfilled, (state, action) => {
      state.data = action.payload;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(getPin.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(setPin.pending, (state) => {
      state.currentState = PinLifecycleState.SAVING;
    });
    builder.addCase(setPin.fulfilled, (state) => {
      state.currentState = PinLifecycleState.SAVED;
      state.data = {
        ...state.data,
        pin: state.localPinChange,
      };
    });
    builder.addCase(setPin.rejected, (state) => {
      state.currentState = PinLifecycleState.ERROR;
    });
    builder.addCase(setCurrentPinState, (state, action) => {
      state.currentState = action.payload;
      state.localPinChange = '';
    });
    builder.addCase(pinChanged, (state, action) => {
      state.localPinChange = action.payload;
      state.currentState = PinLifecycleState.EDITING;
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(selectCustomerLocation, () => initialState);
  },
});

const { reducer } = pinSlice;

export { reducer };
