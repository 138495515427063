import { useEffect } from 'react';
import { FdsButton } from '@lyse-as/formds-react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'app/component-library-wave';
import { useAppSelector } from 'app/hooks/redux-thunk';

import styles from './invoice-postpone.module.scss';

import { GtmDataLayer, GtmErrorType, GtmEvent, pushDataLayer, UserActionScenario } from 'app/utils/gtm-tracking-utils';

export const RejectedPostpone = () => {
  const { errorCode } = useAppSelector((state) => state.invoice.postponeState);
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const { t } = useTranslation();

  const handleBack = () => {
    history.back();
  };

  useEffect(() => {
    const dataLayer: GtmDataLayer = {
      event: GtmEvent.ERROR_MESSAGE,
      error_type: GtmErrorType.INVOICE_ERROR,
      error_content: 'Du kan ikke utsette denne fakturaen',
    };
    pushDataLayer(UserActionScenario.INVOICE_POSTPONE_REJECTED, selectedCustomerLocation, dataLayer);
  }, []);

  return (
    <>
      <Typography component="h1" variant="formds-altibox-subtitle-primary" bold={true}>
        {t('pages.invoice.invoiceErrors.name')}
      </Typography>
      <div className={styles.messageContainer}>
        <Typography component="p" variant="formds-body">
          {t(`pages.invoice.invoiceErrors.DYNAMIC_KEYS.${errorCode}`)}
        </Typography>
      </div>
      <div className={styles.buttonContainer}>
        <FdsButton iconLeft="arrow_back" variant="secondary" onClick={handleBack} fullWidth={true}>
          {t('pages.invoice.invoicePostpone.back')}
        </FdsButton>
      </div>
    </>
  );
};
