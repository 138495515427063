import { CustomerContext, CustomerLocationContext, CustomerType } from 'app/store/types/user-context-types';

export interface GtmDataLayer {
  event?: GtmEvent;
  element_category?: GtmElementCategory;
  element_cta?: GtmElementCta | string;
  element_description?: string;
  element_status?: string;
  element_url?: string;
  partner_id?: string;
  partner_name?: string;
  business_unit?: string;
  error_type?: GtmErrorType;
  error_content?: string;
}

export enum GtmEvent {
  MINE_SIDER = 'mine_sider',
  ERROR_MESSAGE = 'error_message',
}
export enum GtmErrorType {
  INVOICE_ERROR = 'invoice_error',
}

export enum GtmElementCategory {
  ACCOUNT = 'konto',
  TV = 'tv',
  INTERNET = 'internett',
  TELEPHONE = 'telefon',
  INVOICE_OVERVIEW = 'fakturaoversikt',
  INVOICE_POSTPONE = 'utsett_betalingsfristen',
  INVOICE_DETAILS = 'fakturadetaljer',
  AVTALEGIRO = 'avtalegiro',
  TOP_NAVIGATION = 'topp-navigasjon',
}

export enum GtmElementCta {
  FAKTURA = 'faktura',
  FAKTURA_LAST_NED_PDF = 'last_ned_pdf',
  FAKTURA_SE_FAKTURADETALJER = 'se_fakturadetaljer',
  FAKTURA_KOPIER_KONTONUMMER = 'kopier_kontonummer',
  FAKTURA_KOPIER_KID = 'kopier_kid_nummer',
  FAKTURA_LAST_ELDRE_FAKTURAER = 'last_eldre_fakturaer',
  FAKTURA_CREATE_AVTALEGIRO_LINK = 'opprett_avtalegiro_link',
  FAKTURA_CREATE_AVTALEGIRO_CONTINUE = 'fortsett_til_bekreft_avtale',
  FAKTURA_UTSETT_FAKTURA_LINK = 'utsett_faktura_link',
  FAKTURA_UTSETT_FAKTURA_BEKREFT = 'bekreft_utsettelse',
}

export enum UserActionScenario {
  MAIN_MENU_LINK_CLICKED,
  MENU_LINK_CLICKED,
  INVOICE_DOWNLOAD_PDF_CLICKED,
  INVOICE_SEE_DETAILS_CLICKED,
  INVOICE_COPY_KID_CLICKED,
  INVOICE_COPY_ACCOUNT_NUMBER_CLICKED,
  INVOICE_LOAD_MORE_INVOICES_CLICKED,
  INVOICE_CREATE_AVTALEGIRO_LINK_CLICKED,
  INVOICE_CREATE_AVTALEGIRO_CONTINUE_CLICKED,
  INVOICE_LIST_ITEM_CLICKED,
  INVOICE_POSTPONE_LINK_CLICKED,
  INVOICE_POSTPONE_CONFIRM,
  INVOICE_POSTPONE_REJECTED,
}

declare const window: Window & { dataLayer: Record<string, any>[] };

const getGtmBusinessUnit = (customerType?: CustomerType): string => {
  switch (customerType) {
    case CustomerType.BEDRIFT:
      return 'B2B';
    case CustomerType.PRIVAT:
      return 'B2C';
    default:
      return customerType || '';
  }
};

const getCommonDataLayerValues = (customerContext?: CustomerContext) => {
  const business_unit = getGtmBusinessUnit(customerContext?.customerType);

  return {
    event: GtmEvent.MINE_SIDER,
    partner_id: customerContext?.partnerNumber || '',
    partner_name: customerContext?.partnerLegalName || '',
    business_unit,
  };
};

export const pushDataLayer = (
  _scenario: UserActionScenario,
  customerContext?: CustomerContext,
  gtmDataLayer?: GtmDataLayer,
) => {
  const dataLayer = {
    ...getCommonDataLayerValues(customerContext),
    ...gtmDataLayer,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayer);
};

export const getGtmFullUrl = (url: string) => {
  return url.startsWith('http') ? url : window.location.origin + url;
};
