import { useEffect, useState } from 'react';
import { Alert, Button, ButtonType, Typography as T } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { Trans, useTranslation } from 'react-i18next';

import { useAppNavigation } from 'app/utils/navigation-utils';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { fetchNetworkSettings } from 'app/store/actions/network-thunks';
import { Spinner } from 'app/components/spinner';
import { FetchStatus } from 'app/store/root-types';
import { HiddenH1 } from 'app/components/hidden-h1';
import { ContainerFixed } from 'app/components/container';
import { useUpdateAccessPoint } from 'app/hooks/use-network';
import { navigationService } from 'app/service/navigation/navigation-service';
import { useParams } from 'react-router-dom';
import { areBandsIdentical } from 'app/pages/internet/network-common';
import { CustomerServiceMessage } from '../customer-service-message/customer-service-message';

import styles from './ssid-combine-band-confirmation.module.scss';

export const SsidCombineBandConfirmation: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const { goToPath } = useAppNavigation();
  const dispatch = useAppDispatch();
  const customerId = props.userContext.customerCrmId;
  const servicePointId = props.userContext.servicePointId;
  const [isUpdating, setIsUpdating] = useState(false);
  const { isCsr } = useAppSelector((state) => state.auth);
  const [wifiSettingsList, setWifiSettingsList] = useState(['']);
  const [deviceName, setDeviceName] = useState<string | null>();

  const {
    data: networkData,
    fetchStatus,
    updateWifiSettings: { fetchStatus: updateFetchStatus },
  } = useAppSelector((state) => state.network);
  const { wifiSettingId } = useParams();
  const { toggleCombinedWifi } = useUpdateAccessPoint();

  const CUSTOMER_SERVICE_URL = 'https://www.altibox.no/privat/kundeservice/hjelp-til-internett/2-4-eller-5-ghz/';

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchNetworkSettings({ servicePointId, customerId }));
    }

    if (fetchStatus === FetchStatus.FULFILLED && networkData && wifiSettingId) {
      if (networkData.hgw.wifiSettings.includes(wifiSettingId)) {
        setWifiSettingsList(networkData.hgw.wifiSettings);

        // This is also a special case from To-Boks solution.
        if (networkData.accessPoints.every((ap) => ap.inheritFromCpe)) {
          setDeviceName(() => null);
          return;
        }
        if (networkData.accessPoints.length > 0) {
          setDeviceName(() => t('pages.network.ispDevices.hgw'));
        }
        return;
      }
      networkData.accessPoints.forEach((ap) => {
        if (ap.wifiSettings.includes(wifiSettingId)) {
          setDeviceName(ap.name || ap.macAddress);
          setWifiSettingsList(ap.wifiSettings);
        }
      });
    }
  }, [fetchStatus, customerId]);

  useEffect(() => {
    if (isUpdating && updateFetchStatus === FetchStatus.FULFILLED) {
      setIsUpdating(false);
      goToPath(navigationService.PAGES.internetSettingsOverview.url);
    }
  }, [updateFetchStatus]);

  if (!wifiSettingId) {
    goToPath(navigationService.PAGES.internetSettingsOverview.url);
    return null;
  }

  if (!networkData) {
    return <Spinner />;
  }

  const isCombined = areBandsIdentical(networkData, wifiSettingsList);
  const wifiSetting = networkData.wifiSettings.find((wifi) => wifi.id === wifiSettingId);

  const toggleCombined = () => {
    setIsUpdating(true);
    toggleCombinedWifi({
      wifiIds: wifiSettingsList,
      wifiSettings: networkData.wifiSettings,
      enableCombined: !isCombined,
    });
  };

  const Buttons = () => (
    <div className={styles.buttonRow}>
      {isUpdating && updateFetchStatus === FetchStatus.PENDING && <Spinner />}
      {!isUpdating && updateFetchStatus !== FetchStatus.PENDING && (
        <>
          <Button buttonType={ButtonType.PRIMARY_B} type="submit" onClick={toggleCombined}>
            {isCombined
              ? t('pages.network.advanced.wifi.helpTexts.divide.buttons.confirm')
              : t('pages.network.advanced.wifi.helpTexts.combine.buttons.confirm')}
          </Button>
          <Button buttonType={ButtonType.SECONDARY} type="button" onClick={() => history.back()}>
            {isCombined
              ? t('pages.network.advanced.wifi.helpTexts.combine.buttons.cancel')
              : t('pages.network.advanced.wifi.helpTexts.divide.buttons.cancel')}
          </Button>
        </>
      )}
    </div>
  );

  const InfoBox = () => (
    <Alert
      alertType="info"
      heading={t('pages.network.advanced.wifi.helpTexts.combine.infoBox.header')}
      headingElement="div"
      hideIcon={true}
      isExpandable={true}
      className={styles.infoBox}
    >
      <T component="p" variant="paragraph3" className={styles.infoParagraph}>
        {t('pages.network.advanced.wifi.helpTexts.combine.infoBox.paragraph1')}
      </T>
      <T component="p" variant="paragraph3">
        <Trans i18nKey="pages.network.advanced.wifi.helpTexts.combine.infoBox.paragraph2">
          Les mer om dette hos <a href={CUSTOMER_SERVICE_URL}>Kundeservice</a>
        </Trans>
      </T>
    </Alert>
  );

  const CombinedInformation = () => (
    <>
      <T component="h2" variant="headline3" id="confirmationLabel" className={styles.header}>
        {t('pages.network.advanced.wifi.helpTexts.divide.title')}
      </T>
      <T component="strong" variant="headline3" className={styles.subHeader}>
        {t('pages.network.advanced.wifi.helpTexts.divide.paragraph1.header')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subParagraph}>
        {t('pages.network.advanced.wifi.helpTexts.divide.paragraph1.subParagraph1')}
      </T>
      <T component="h2" variant="headline3" className={styles.subHeader}>
        {t('pages.network.advanced.wifi.helpTexts.divide.paragraph2.header')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subParagraph}>
        {t('pages.network.advanced.wifi.helpTexts.divide.paragraph2.subParagraph1')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subParagraph}>
        <Trans i18nKey="pages.network.advanced.wifi.helpTexts.divide.paragraph2.subParagraph2">
          Usikker? <a href={CUSTOMER_SERVICE_URL}>Kundeservice</a>
        </Trans>
      </T>
      {deviceName && (
        <T component="p" variant="paragraph1" className={styles.deviceName}>
          {t('pages.network.advanced.wifi.helpTexts.deviceName', { deviceName })}
        </T>
      )}
    </>
  );

  const NotCombinedInformation = () => (
    <>
      <T component="h2" variant="headline3" id="confirmationLabel" className={styles.header}>
        {t('pages.network.advanced.wifi.helpTexts.combine.title')}
      </T>
      <T component="h2" variant="headline3" className={styles.subHeader}>
        {t('pages.network.advanced.wifi.helpTexts.combine.paragraph1.header')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subParagraph}>
        {t('pages.network.advanced.wifi.helpTexts.combine.paragraph1.subParagraph1')}
      </T>
      <T component="p" variant="paragraph1" className={styles.subParagraph}>
        {t('pages.network.advanced.wifi.helpTexts.combine.paragraph1.subParagraph2')}
      </T>
      <T component="h2" variant="headline5" className={styles.subHeader}>
        {t('pages.network.advanced.wifi.helpTexts.combine.paragraph2.header')}
      </T>
      {deviceName && (
        <T component="p" variant="paragraph1" className={styles.deviceName}>
          {t('pages.network.advanced.wifi.helpTexts.deviceName', { deviceName })}
        </T>
      )}
      <T component="p" variant="paragraph1">
        {t('pages.network.advanced.wifi.helpTexts.combine.paragraph2.networkName', {
          ssidName: wifiSetting?.ssid,
        })}
      </T>
      <T component="p" variant="paragraph1">
        {t('pages.network.advanced.wifi.helpTexts.combine.paragraph2.networkPassword', {
          ssidPassword: wifiSetting?.encKey,
        })}
      </T>
      <InfoBox />
    </>
  );

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1 focusHeading={false}>{t('pages.network.advanced.name').toString()}</HiddenH1>
      {isCsr && <CustomerServiceMessage displayCannotCombineWifi={true} />}
      <div className={styles.container}>
        {isCombined ? <CombinedInformation /> : <NotCombinedInformation />}
        {!isCsr && <Buttons />}
      </div>
    </ContainerFixed>
  );
};
