import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { fetchConsent } from 'app/store/actions/consent-thunks';
import { Col, Grid, GridWidth, Typography } from 'app/component-library-wave';
import { FdsButton } from '@lyse-as/formds-react';
import { dismissVerificationPrompt } from 'app/store/actions/core-actions';
import { Partner } from 'app/utils/partner-utils';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import { LYSE_GENERAL_MARKETING_CONSENT_URL } from 'app/pages/account/privacy-and-concents/privacy-and-concents';
import { hasInformationPromptGraceTimeExpired } from 'app/utils/date-utils';
import styles from './consent-reminder-notification.module.scss';

export const ConsentReminderNotification = () => {
  const dispatch = useAppDispatch();
  const { consent } = useAppSelector((state) => state.consent);
  const { goToPath } = useAppNavigation();
  const partnerNumber = useAppSelector((state) => state.userContext.selectedCustomerLocation?.partnerNumber);
  const { dismissedVerificationPrompt } = useAppSelector((state) => state.core);
  const [showConsentReminder, setShowConsentReminder] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!hasInformationPromptGraceTimeExpired()) {
      return;
    }

    if (!consent) {
      dispatch(fetchConsent());
      return;
    }
    if (consent.generalMarketingEmail || consent.generalMarketingSms) {
      return;
    }
    setShowConsentReminder(!dismissedVerificationPrompt);
  }, [consent, dismissedVerificationPrompt]);

  const postpone = () => {
    dispatch(dismissVerificationPrompt());
    setShowConsentReminder(false);
  };

  const updateMarketingConsent = () => {
    if (partnerNumber === Partner.NO_Lyse) {
      goToPath(LYSE_GENERAL_MARKETING_CONSENT_URL);
      return;
    }
    goToPath(navigationService.PAGES.generalMarketing.url);
  };

  if (!showConsentReminder) {
    return null;
  }

  return (
    <Grid width={GridWidth.WIDE}>
      <Col sm={2} md={6} lg={12}>
        <aside data-testid="reminder-notification" className={styles.container}>
          <Typography component="h2" variant="formds-lead" bold={true}>
            {t('features.reminderNotifications.headline')}
          </Typography>
          <Typography component="p" variant="formds-body">
            {t('features.reminderNotifications.paragraph')}
          </Typography>
          <div className={styles.buttonRow}>
            <FdsButton onClick={updateMarketingConsent}>{t('features.reminderNotifications.cta')}</FdsButton>
            <FdsButton variant="tertiary" onClick={postpone}>
              {t('features.reminderNotifications.postpone')}
            </FdsButton>
          </div>
        </aside>
      </Col>
    </Grid>
  );
};
