import { createAction } from '@reduxjs/toolkit';

export interface AuthCallbackSuccessPayload {
  accessToken: string;
  idToken: string;
  sessionExpirationTimestamp: number;
}
export interface ProvAuthSuccessPayload {
  sessionTicket: string;
  sessionExpirationTimestamp: number;
}

export const startProvAuth = createAction<undefined>('START_PROV_AUTH');
export const authCallbackFailure = createAction<string>('AUTH_CALLBACK_FAILURE');
export const authCallbackSuccess = createAction<AuthCallbackSuccessPayload>('AUTH_CALLBACK_SUCCESS');
export const provAuthFailure = createAction<string>('PROV_AUTH_FAILURE');
export const provAuthSuccess = createAction<ProvAuthSuccessPayload>('PROV_AUTH_SUCCESS');
