import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { addToastMessage } from './toast-message-actions';
import { i18n } from 'app/i18n/i18n';

// Thunks
export const setPin = createAsyncThunk<
  void,
  MinesiderBackend.SetPinRequest,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('pin/setPin', async (newPin: MinesiderBackend.SetPinRequest, thunkApi) =>
  minesiderBackendService
    .setPin(newPin)
    .then((result) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('features.toast.save.success'),
          alertType: 'success',
          role: 'status',
        }),
      );
      return result;
    })
    .catch((error) => {
      thunkApi.dispatch(
        addToastMessage({
          heading: i18n.t('features.toast.save.rejected'),
          alertType: 'warning',
          role: 'alert',
        }),
      );
      thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse);
    }),
);

export const getPin = createAsyncThunk<
  MinesiderBackend.CustomerPin,
  string,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('pin/getPin', async (customerId: string, thunkApi) =>
  minesiderBackendService
    .getPin(customerId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);
