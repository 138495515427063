import { Trans, useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';
import { FdsLink, FdsNavListItem, FdsNavListItems } from '@lyse-as/formds-react';

import { ContainerFixed } from 'app/components/container';
import { CustomerPage } from 'app/pages/page-wrapper';
import { navigationService } from 'app/service/navigation/navigation-service';
import { Partner } from 'app/utils/partner-utils';

import styles from './privacy-and-concents.module.scss';

export const LYSE_GENERAL_MARKETING_CONSENT_URL =
  'https://www.lyse.no/minesider/mitt-kundeforhold/samtykke#markedsforing';

export const privacyAndConsents: CustomerPage = (props) => {
  const { t } = useTranslation();

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.privacyAndConcentContainer}>
        <Typography variant="headline4" component="h1" className={styles.header} maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.consent.privacyAndConsents.heading')}
        </Typography>

        <Typography variant="paragraph2" component="p" className={styles.lead} maxBreakpoint={Breakpoint.MOBILE}>
          {t('pages.consent.privacyAndConsents.lead')}
        </Typography>

        <FdsNavListItems bordered={true} className={styles.navList}>
          <FdsNavListItem
            header={t('pages.consent.privacyAndConsents.navlist.generalMarketing')}
            secondaryHeader={t('pages.consent.privacyAndConsents.navlist.generalMarketingSecondary')}
            href={
              props.userContext.partnerNumber === Partner.NO_Lyse
                ? LYSE_GENERAL_MARKETING_CONSENT_URL
                : navigationService.PAGES.generalMarketing.url
            }
            navIcon="chevron_right"
            preventReload={true}
          />
          <FdsNavListItem
            header={t('pages.consent.privacyAndConsents.navlist.reservations')}
            secondaryHeader={t('pages.consent.privacyAndConsents.navlist.reservationsSecondary')}
            href={
              props.userContext.partnerNumber === Partner.NO_Lyse
                ? 'https://www.lyse.no/minesider/mitt-kundeforhold/samtykke#reservasjoner'
                : navigationService.PAGES.reservations.url
            }
            navIcon="chevron_right"
            preventReload={true}
          />

          <FdsNavListItem
            header={t('pages.consent.privacyAndConsents.navlist.newsAndOffers')}
            secondaryHeader={t('pages.consent.privacyAndConsents.navlist.newsAndOffersSecondary')}
            href={navigationService.PAGES.newsAndOffers.url}
            navIcon="chevron_right"
            preventReload={true}
          />
        </FdsNavListItems>

        <Typography variant="paragraph3" maxBreakpoint={Breakpoint.MOBILE} component="p" className={styles.moreInfo}>
          <Trans i18nKey="pages.consent.privacyAndConsents.privacyStatementLinkText">
            Les mer om{' '}
            <FdsLink href="https://www.altibox.no/privat/kundeservice/personvern/personvernerklaering/">
              Altibox sin personvernserklæring
            </FdsLink>
          </Trans>
        </Typography>
      </div>
    </ContainerFixed>
  );
};
