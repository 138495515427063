import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Typography } from 'app/component-library-wave';
import { FdsToggleSwitch } from '@lyse-as/formds-react';
import { FdsToggleSwitchChangeEventDetail, FdsToggleSwitchCustomEvent } from '@lyse-as/formds-core';

import { getSubscriptions, updateSubscriptions } from 'app/store/actions/news-and-offers-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { CustomerType } from 'app/store/types/user-context-types';
import { ContainerFixed } from 'app/components/container';
import { SubscriptionGroup } from 'app/features/subscriptions';
import { Spinner } from 'app/components/spinner';

import styles from './news-and-offers.module.scss';

export enum SubscriptionTypes {
  NEWSLETTER_ALTIBOX_MOVIES_SERIES = 'subNewsletterAltiboxMoviesSeries',
  NEWLETTERS = 'subNewsletters',
  MAGAZINE = 'subMagazine',
  OPERATIONAL_MESSAGES = 'operationalMessages',
}

interface Subscription {
  id: SubscriptionTypes;
  name: string;
  description: string;
}

interface SubscriptionConfig {
  subscriptionGroup: string;
  subscriptions: Subscription[];
}

export const NewsAndOffers: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { subscriptions, getSubscriptionsFetchStatus, updateSubscriptionsStatus } = useAppSelector(
    (state) => state.newsAndOffers,
  );
  const customerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);

  const subscriptionConfigEnterprise: SubscriptionConfig[] = [
    {
      subscriptionGroup: t('pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.name'),
      subscriptions: [
        {
          id: SubscriptionTypes.NEWLETTERS,
          name: t(
            'pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNewsEnterprise.name',
          ),
          description: t(
            'pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNewsEnterprise.description',
          ),
        },
      ],
    },
  ];

  const subscriptionConfigPrivate: SubscriptionConfig[] = [
    {
      subscriptionGroup: t('pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.name'),
      subscriptions: [
        {
          id: SubscriptionTypes.NEWSLETTER_ALTIBOX_MOVIES_SERIES,
          name: t('pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.movieNews.name'),
          description: t(
            'pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.movieNews.description',
          ),
        },
        {
          id: SubscriptionTypes.NEWLETTERS,
          name: t('pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNews.name'),
          description: t(
            'pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.productNews.description',
          ),
        },
        {
          id: SubscriptionTypes.OPERATIONAL_MESSAGES,
          name: t('pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.notifications.name'),
          description: t(
            'pages.newsAndOffers.subscriptions.subscriptionGroup.newsLetters.subscriptions.notifications.description',
          ),
        },
      ],
    },
    {
      subscriptionGroup: t('pages.newsAndOffers.subscriptions.subscriptionGroup.magazines.name'),
      subscriptions: [
        {
          id: SubscriptionTypes.MAGAZINE,
          name: t('pages.newsAndOffers.subscriptions.subscriptionGroup.magazines.subscriptions.offersTipsTricks.name'),
          description: t(
            'pages.newsAndOffers.subscriptions.subscriptionGroup.magazines.subscriptions.offersTipsTricks.description',
          ),
        },
      ],
    },
  ];

  const subscriptionConfig =
    customerLocation?.customerType === CustomerType.BEDRIFT ? subscriptionConfigEnterprise : subscriptionConfigPrivate;

  const onToggleClick = (e: FdsToggleSwitchCustomEvent<FdsToggleSwitchChangeEventDetail>) => {
    toggleSwitch(e.detail.value);
  };

  const toggleSwitch = (subscription: SubscriptionTypes) => {
    const newSubscriptions = { ...subscriptions };
    switch (subscription) {
      case SubscriptionTypes.NEWLETTERS:
        newSubscriptions.subNewsletters = !subscriptions.subNewsletters;
        break;
      case SubscriptionTypes.MAGAZINE:
        newSubscriptions.subMagazine = !subscriptions.subMagazine;
        break;
      case SubscriptionTypes.NEWSLETTER_ALTIBOX_MOVIES_SERIES:
        newSubscriptions.subNewsletterAltiboxMoviesSeries = !subscriptions.subNewsletterAltiboxMoviesSeries;
        break;
      case SubscriptionTypes.OPERATIONAL_MESSAGES:
        newSubscriptions.operationalMessages = !subscriptions.operationalMessages;
        break;
    }
    dispatch(updateSubscriptions(newSubscriptions));
  };

  useEffect(() => {
    if (getSubscriptionsFetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(getSubscriptions());
    }
  }, [getSubscriptionsFetchStatus]);

  return (
    <ContainerFixed isNarrow={true} className={styles.container}>
      <div>
        <Typography variant="headline4" component="h1" className={styles.heading} maxBreakpoint={Breakpoint.TABLET}>
          {t('pages.newsAndOffers.name')}
        </Typography>
        <Typography variant="headline5" component="h2" className={styles.leadHeading} maxBreakpoint={Breakpoint.MOBILE}>
          {t('pages.newsAndOffers.leadHeading')}
        </Typography>
        <Typography
          variant="paragraph2"
          component="div"
          className={styles.lead}
          maxBreakpoint={Breakpoint.MOBILE}
          renderAsHTML={true}
        >
          {t('pages.newsAndOffers.lead')}
        </Typography>
      </div>

      <>
        {getSubscriptionsFetchStatus === FetchStatus.PENDING && <Spinner />}
        {getSubscriptionsFetchStatus === FetchStatus.REJECTED && (
          <Alert
            heading={t('pages.newsAndOffers.subscriptions.errorMessages.getSubscriptionsFailed')}
            headingElement="strong"
            alertType="warning"
            role="alert"
            isExpandable={false}
          />
        )}
        {getSubscriptionsFetchStatus === FetchStatus.FULFILLED && (
          <div className={styles.options}>
            {subscriptionConfig.map((config, groupIndex) => (
              <SubscriptionGroup name={config.subscriptionGroup} key={config.subscriptionGroup}>
                {config.subscriptions.map(({ id, name, description }, index) => {
                  return (
                    <li key={id}>
                      <FdsToggleSwitch
                        framed={true}
                        description={description}
                        fullWidth={true}
                        defaultChecked={subscriptions[id]}
                        value={id}
                        onFdsToggleSwitchChecked={onToggleClick}
                        onKeyDown={(e) => {
                          if (e.key !== ' ') {
                            return;
                          }
                          if (
                            // Strange bug in FormDS that first element only
                            // trigges both keyDown and onFdsChecked above.
                            // Compensate by ignoring first element.
                            groupIndex === 0 &&
                            index === 0
                          ) {
                            return;
                          }
                          e.preventDefault();
                          toggleSwitch(id);
                        }}
                        disabled={updateSubscriptionsStatus === FetchStatus.PENDING}
                        className={styles.toggleSwitch}
                      >
                        {name}
                      </FdsToggleSwitch>
                    </li>
                  );
                })}
              </SubscriptionGroup>
            ))}
          </div>
        )}
        {updateSubscriptionsStatus === FetchStatus.REJECTED && (
          <Alert
            heading={t('pages.newsAndOffers.subscriptions.errorMessages.subscriptionUpdateFailed')}
            headingElement="strong"
            alertType="warning"
            role="alert"
            isExpandable={false}
          />
        )}
      </>
    </ContainerFixed>
  );
};
