import { createSlice, Slice } from '@reduxjs/toolkit';

import { startProvAuth } from '../actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { FetchStatus, InvoiceState } from '../root-types';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import {
  getInvoiceLines,
  getInvoicePaymentInformation,
  getInvoices,
  getMoreInvoices,
  getInvoicePdf,
  postponeInvoice,
  getInvoicePaymentAgreementParameters,
} from 'app/store/actions/invoice-thunks';
import {
  resetPostponeInvoice,
  setLoadedInvoicesFrom,
  setPdfErrorCode,
  setPostponeRejectionCode,
} from '../actions/invoice-actions';

const initialState: InvoiceState = {
  fetchStatus: FetchStatus.NOT_STARTED,
  postponeState: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  invoices: [],
  invoicePdf: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  invoiceLines: {
    invoiceLines: [],
  },
  paymentAgreement: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
};

const invoiceSlice: Slice<InvoiceState> = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(getInvoices.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(getInvoices.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(getMoreInvoices.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getMoreInvoices.fulfilled, (state, action) => {
      state.invoices = state.invoices?.concat(action.payload);
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(getMoreInvoices.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(getInvoicePaymentInformation.fulfilled, (state, action) => {
      state.paymentInformation = action.payload;
    });

    builder.addCase(setLoadedInvoicesFrom, (state, action) => {
      state.loadedInvoicesFrom = action.payload;
    });
    builder.addCase(getInvoiceLines.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getInvoiceLines.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(getInvoiceLines.fulfilled, (state, action) => {
      state.invoiceLines = {
        invoiceId: action.meta.arg,
        invoiceLines: action.payload,
      };
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(postponeInvoice.pending, (state) => {
      state.postponeState = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(postponeInvoice.rejected, (state, action) => {
      console.log(state, action);
      state.postponeState = {
        ...state.postponeState,
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(setPostponeRejectionCode, (state, action) => {
      state.postponeState = {
        ...state.postponeState,
        errorCode: action.payload,
      };
    });
    builder.addCase(postponeInvoice.fulfilled, (state, action) => {
      state.postponeState = {
        data: action.payload,
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(resetPostponeInvoice, (state) => {
      state.postponeState.fetchStatus = FetchStatus.NOT_STARTED;
    });
    builder.addCase(getInvoicePdf.fulfilled, (state) => {
      state.invoicePdf.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(getInvoicePdf.rejected, (state) => {
      state.invoicePdf.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(setPdfErrorCode, (state, action) => {
      state.invoicePdf.errorCode = action.payload;
    });
    builder.addCase(getInvoicePdf.pending, (state) => {
      state.invoicePdf = {
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(getInvoicePaymentAgreementParameters.pending, (state) => {
      state.paymentAgreement.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getInvoicePaymentAgreementParameters.fulfilled, (state, action) => {
      state.paymentAgreement.data = action.payload;
      state.paymentAgreement.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(getInvoicePaymentAgreementParameters.rejected, (state) => {
      state.paymentAgreement.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

const { reducer } = invoiceSlice;

export { reducer };
