import React from 'react';

import { useAnnounceNavigation } from 'app/hooks';

import styles from './hidden-h1.module.scss';
import { HeadingLevel } from 'app/component-library-wave';

interface Props {
  children: React.ReactNode;
  headingLevel?: HeadingLevel;
  focusHeading?: boolean;
}

export const HiddenH1: React.FC<Props> = ({ children, headingLevel = HeadingLevel.H1, focusHeading = true }) => {
  const { announceElement } = useAnnounceNavigation<HTMLHeadingElement>();
  if (!focusHeading) {
    return React.createElement(headingLevel, { className: styles.visuallyHiddenText }, children);
  }

  const headerProps = {
    ref: announceElement,
    className: styles.visuallyHiddenText,
  };

  return React.createElement(headingLevel, headerProps, children);
};
