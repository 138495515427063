import { useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { FdsAlert, FdsLink } from '@lyse-as/formds-react';

import { Breakpoint, Typography } from 'app/component-library-wave';

import { OperationalMessageAlert } from 'app/components/operational-message-alert/operational-message-alert';
import { i18n } from 'app/i18n/i18n';
import { ActiveCustomerLocationContext, isActiveLocation } from 'app/store/types/user-context-types';
import { fetchOperationalMessages, fetchNotifications } from 'app/store/actions/operational-messages-thunks';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { Spinner } from 'app/components/spinner';
import { minutesToHoursAndMinutes, iso8601ToDayFullMonthYearHourMinute } from 'app/utils/date-utils';

import styles from './operational-messages.module.scss';

export const OperationalMessages = () => {
  const dispatch = useAppDispatch();
  const selectedCustomerLocation = useAppSelector((state) => state.userContext.selectedCustomerLocation);
  const { messages, isFetching, notifications, isFetchingNotifications } = useAppSelector(
    (state) => state.operationalMessages,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedCustomerLocation && isActiveLocation(selectedCustomerLocation)) {
      dispatch(fetchOperationalMessages(selectedCustomerLocation.siteId));
      dispatch(
        fetchNotifications({
          customerCrmId: selectedCustomerLocation.customerCrmId,
          servicePointId: selectedCustomerLocation.servicePointId,
        }),
      );
    }
  }, [dispatch, selectedCustomerLocation]);

  const getAlertVariant = ({ status, notificationType }: MinesiderBackend.CustomerNotification) => {
    if (status === 'CLOSED') {
      return 'success';
    }
    switch ((notificationType || '').toUpperCase()) {
      case 'ABUSE':
      case 'INCIDENT':
        return 'error';
      case 'MAINTENANCE':
        return 'warning';
      default:
        return 'info';
    }
  };

  const renderInfoColumn = (label: string, value: string) => (
    <div className={styles.column}>
      <Typography
        className={styles.columnHeader}
        variant="uiText3"
        component="div"
        bold={true}
        maxBreakpoint={Breakpoint.TABLET}
      >
        {label}:
      </Typography>
      <Typography variant="paragraph3" component="div" maxBreakpoint={Breakpoint.TABLET}>
        {value}
      </Typography>
    </div>
  );

  const renderNotifications = () => {
    {
      return notifications?.map((n) => (
        <FdsAlert key={n.notificationId} variant={getAlertVariant(n)} className={styles.alert}>
          <h2 slot="alert-heading">{n.subject}</h2>
          <p>{n.body}</p>

          <div className={styles.infoColumns}>
            {n.start &&
              renderInfoColumn(
                t('features.operationalMessages.notifications.start'),
                iso8601ToDayFullMonthYearHourMinute(n.start),
              )}
            {n.messageTimestamp &&
              renderInfoColumn(
                t('features.operationalMessages.notifications.messageTimestamp'),
                iso8601ToDayFullMonthYearHourMinute(n.messageTimestamp),
              )}
            {n.estimatedDowntimeInMinutes &&
              renderInfoColumn(
                t('features.operationalMessages.notifications.estimatedDowntimeInMinutes'),
                minutesToHoursAndMinutes(n.estimatedDowntimeInMinutes),
              )}
            {n.estimatedResolution &&
              renderInfoColumn(
                t('features.operationalMessages.notifications.estimatedResolution'),
                iso8601ToDayFullMonthYearHourMinute(n.estimatedResolution),
              )}
            {n.end &&
              renderInfoColumn(
                t('features.operationalMessages.notifications.end'),
                iso8601ToDayFullMonthYearHourMinute(n.end),
              )}
          </div>
        </FdsAlert>
      ));
    }
  };

  const noOperationalMessages =
    (!messages || messages.length < 1) &&
    (!notifications || notifications.length < 1) &&
    !isFetching &&
    !isFetchingNotifications;

  const renderOperationalMessages = () => {
    if (messages && messages.length > 0) {
      return messages.map((message) => (
        <OperationalMessageAlert
          key={message.key}
          message={message}
          activeUserContext={selectedCustomerLocation as ActiveCustomerLocationContext}
        />
      ));
    }
  };

  return (
    <aside data-testid="operational-messages" className={styles.container}>
      {(isFetching || isFetchingNotifications) && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      {noOperationalMessages && (
        <FdsAlert variant="info" disableDismiss={true}>
          <strong slot="alert-heading">{i18n.t('features.operationalMessages.noMessages')}</strong>
        </FdsAlert>
      )}
      {renderOperationalMessages()}
      {renderNotifications()}

      {/* Hidden for now. Link to Aria Smart Selvbetjening when the system is live */}
      <p hidden={true}>
        <Trans i18nKey="features.operationalMessages.troubleshootingLink">
          Problemer med dine tjenester?
          <FdsLink icon="launch" href="#">
            Gjennomfør feilsøk selv
          </FdsLink>
        </Trans>
      </p>
    </aside>
  );
};
