import { createSlice, Slice } from '@reduxjs/toolkit';

import { startProvAuth } from 'app/store/actions/auth-actions';
import { logout } from '../actions/auth-thunks';
import { OperationalMessageState } from 'app/store/root-types';
import { fetchNotifications, fetchOperationalMessages } from '../actions/operational-messages-thunks';

const initialState: OperationalMessageState = {
  isFetching: false,
  isFetchingNotifications: false,
};

const operationalMessageSlice: Slice<OperationalMessageState> = createSlice({
  name: 'operationalMessage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOperationalMessages.pending, (state) => {
      state.isFetching = true;
      return state;
    });
    builder.addCase(fetchOperationalMessages.fulfilled, (state, action) => {
      state.isFetching = false;
      state.messages = action.payload.map((message) => ({
        key: message.key ? message.key : '',
        webSummary: message.webSummary ? message.webSummary : '',
        webDescription: message.webDescription ? message.webDescription : '',
        webTemplate: message.webTemplate ? message.webTemplate : '',
        partners: message.partners ? message.partners : [],
        affectedProducts: message.affectedProducts ? message.affectedProducts : [],
        dateOfOccurence: message.errorOccurred ? message.errorOccurred : '',
      }));
      return state;
    });
    builder.addCase(fetchOperationalMessages.rejected, (state) => {
      state.isFetching = false;
      return state;
    });
    builder.addCase(fetchNotifications.pending, (state) => {
      state.isFetchingNotifications = true;
      return state;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.isFetchingNotifications = false;
      state.notifications = action.payload;
      return state;
    });
    builder.addCase(fetchNotifications.rejected, (state) => {
      state.isFetchingNotifications = false;
      return state;
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
  },
});

const { reducer } = operationalMessageSlice;

export { reducer };
