import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Breakpoint, Typography } from 'app/component-library-wave';
import { FdsButton, FdsRadio, FdsRadioItem } from '@lyse-as/formds-react';

import { ContainerFixed } from 'app/components/container';
import { CustomerPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { fetchConsent, updateConsents } from 'app/store/actions/consent-thunks';

import styles from './reservations.module.scss';

export const Reservations: CustomerPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const consent = useAppSelector((state) => state.consent.consent);
  const fetchStatus = useAppSelector((state) => state.consent.fetchStatus);
  const [selectedConsent, setSelectedConsent] = useState<'yes' | 'no' | undefined>();

  useEffect(() => {
    switch (consent?.simpleProfiling) {
      case undefined:
        setSelectedConsent(undefined);
        break;
      case true:
        setSelectedConsent('yes');
        break;
      default:
        setSelectedConsent('no');
        break;
    }
  }, [consent]);

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchConsent());
    }
  }, [dispatch, fetchStatus]);

  const onSave = () => {
    if (!consent || selectedConsent === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Consent store values missing.');
      return;
    }

    const newConsents = { ...consent, simpleProfiling: selectedConsent === 'yes' };

    dispatch(updateConsents(newConsents));
  };

  return (
    <ContainerFixed isNarrow={true}>
      <div className={styles.pageContainer}>
        <Typography variant="headline4" component="h1" maxBreakpoint={Breakpoint.TABLET} className={styles.heading}>
          {t('pages.consent.reservations.heading')}
        </Typography>

        <Typography variant="paragraph2" component="p" maxBreakpoint={Breakpoint.MOBILE} className={styles.lead}>
          {t('pages.consent.reservations.lead', { partnerName: props.userContext.partnerLegalName })}
        </Typography>

        <div className={styles.card}>
          <div className={styles.cardDescription}>
            <Trans
              i18nKey={'pages.consent.reservations.description'}
              values={{ partnerName: props.userContext.partnerLegalName }}
            >
              <strong />
              <strong />
            </Trans>
          </div>

          <FdsRadio
            label={''}
            vertical={true}
            disabled={fetchStatus === FetchStatus.PENDING}
            defaultValue={selectedConsent}
          >
            <FdsRadioItem onFdsRadioChecked={() => setSelectedConsent('yes')} value={'yes'}>
              {t('pages.consent.reservations.deny')}
            </FdsRadioItem>
            <FdsRadioItem onFdsRadioChecked={() => setSelectedConsent('no')} value={'no'}>
              {t('pages.consent.reservations.permit')}
            </FdsRadioItem>
          </FdsRadio>
        </div>

        <FdsButton
          onClick={onSave}
          disabled={selectedConsent === undefined}
          isLoading={fetchStatus === FetchStatus.PENDING}
        >
          {t('pages.consent.ctaSave')}
        </FdsButton>
      </div>
    </ContainerFixed>
  );
};
