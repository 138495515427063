import { FdsButton } from '@lyse-as/formds-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Breakpoint, Typography as T } from 'app/component-library-wave';
import { ContainerFixed } from 'app/components/container';
import { CustomerPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FetchStatus } from 'app/store/root-types';
import { ContainerLayoutMode } from 'app/components/container/container';
import { useEffect, useState } from 'react';
import { ParamUtils } from 'app/utils/param-utils';
import { InvoiceOverview } from '../invoice-overview/invoice-overview';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { navigationService } from 'app/service/navigation/navigation-service';
import { getInvoiceLines } from 'app/store/actions/invoice-thunks';
import { InvoiceLines } from '../invoice-lines/invoice-lines';
import { useDownloadInvoicePdf } from '../invoice-utils';
import {
  getGtmFullUrl,
  GtmDataLayer,
  GtmElementCategory,
  GtmElementCta,
  pushDataLayer,
  UserActionScenario,
} from 'app/utils/gtm-tracking-utils';
import { iso8601ToFullMonthAndYear } from 'app/utils/date-utils';

import invoiceStyles from '../invoice.module.scss';
import styles from './invoice-details.module.scss';

export const InvoiceDetailsPage: CustomerPage = ({ userContext }) => {
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState<MinesiderBackend.Invoice>();
  const { goToPath } = useAppNavigation();
  const { invoices, paymentInformation, invoiceLines } = useAppSelector((state) => state.invoice);
  const { downloadPdf, downloadFetchStatus } = useDownloadInvoicePdf();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const invoiceId = ParamUtils.getQueryParam('invoiceId');
    const currentInvoice = invoices?.find((invoice) => invoice.id === invoiceId);

    if (currentInvoice) {
      setInvoice(currentInvoice);
    } else {
      goToPath(navigationService.PAGES.invoice.url);
    }
  }, [invoices]);

  useEffect(() => {
    if (invoice?.id && invoiceLines.invoiceId !== invoice.id) {
      dispatch(getInvoiceLines(invoice.id));
    }
  }, [invoice]);

  const postponeInvoiceClicked = (invoice: MinesiderBackend.Invoice) => {
    const postponeUrl = navigationService.getPostponeUrl(invoice.id!);

    const dataLayer: GtmDataLayer = {
      element_category: GtmElementCategory.INVOICE_DETAILS,
      element_cta: GtmElementCta.FAKTURA_UTSETT_FAKTURA_LINK,
      element_status: invoice.settlementState,
      element_description: iso8601ToFullMonthAndYear(invoice?.billDate || ''),
      element_url: getGtmFullUrl(navigationService.PAGES.invoicePostpone.url),
    };
    pushDataLayer(UserActionScenario.INVOICE_POSTPONE_LINK_CLICKED, userContext, dataLayer);

    goToPath(postponeUrl);
  };

  return (
    <ContainerFixed mode={ContainerLayoutMode.Medium} className={styles.pageContainer}>
      <T
        variant="formds-altibox-header-tertiary"
        component="h1"
        maxBreakpoint={Breakpoint.TABLET}
        bold={true}
        className={styles.pageHeading}
      >
        {t('pages.invoiceDetails.name')}
      </T>
      {invoice && (
        <div className={classNames(styles.borderContainer, styles.fullDetails)}>
          <InvoiceOverview
            invoice={invoice}
            showFullDetails={true}
            paymentInformation={paymentInformation}
            elementCategory={GtmElementCategory.INVOICE_DETAILS}
          />
          <div className={invoiceStyles.detailsButtonContainer}>
            <FdsButton
              className={styles.pdfButton}
              variant="primary"
              iconRight="launch"
              fullWidth={true}
              onClick={() => downloadPdf(invoice, GtmElementCategory.INVOICE_DETAILS)}
              isLoading={downloadFetchStatus === FetchStatus.PENDING}
            >
              {t('pages.invoice.downloadInvoice')}
            </FdsButton>
            <FdsButton fullWidth={true} variant="secondary" onClick={() => postponeInvoiceClicked(invoice)}>
              {t('pages.invoice.postponeInvoice')}
            </FdsButton>
          </div>
        </div>
      )}

      <T
        variant="formds-lead"
        component="h2"
        maxBreakpoint={Breakpoint.TABLET}
        bold={true}
        className={styles.invoiceLinesHeading}
      >
        {t('pages.invoiceDetails.invoiceLines.heading')}
      </T>
      <div className={classNames(styles.borderContainer, styles.invoiceLinesContainer)}>
        <InvoiceLines invoicelines={invoiceLines.invoiceLines} taxIncludedSum={invoice?.taxIncludedSum} />
      </div>
      <T variant="formds-body" component="p" maxBreakpoint={Breakpoint.TABLET} className={styles.includingTaxMessage}>
        {t('pages.invoiceDetails.includingTaxMessage')}
      </T>
    </ContainerFixed>
  );
};
