import { useTranslation } from 'react-i18next';
import { Typography } from 'app/component-library-wave';
import { ActiveCustomerLocationPage } from '../../../page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { voipSettingsTabs } from 'app/utils/navigation-utils';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { FdsToggleSwitch } from '@lyse-as/formds-react';
import { useVoipSettings } from '../voip-common';
import { updateVoipSettings } from 'app/store/actions/voip-settings-thunks';
import { FdsToggleSwitchChangeEventDetail, FdsToggleSwitchCustomEvent } from '@lyse-as/formds-core';
import { ErrorTypes, FetchStatus, FriendlyErrorMessage } from 'app/store/root-types';
import { DeleteRuleConfirmation, VoipRuleType } from '../components/delete-rule-confirm';
import { NumberDropDown } from '../components/number-dropdown';
import { RejectionCard } from '../components/rejection-card';
import { VoipSettingsTitle } from '../components/voip-settings-title';
import { useEffect } from 'react';
import { Service } from 'app/service/navigation/pages/page-types';
import { raiseGlobalError } from 'app/store/actions/core-actions';
import styles from '../voip-common.module.scss';
import { VoipContainer } from './container';

export const VoipCalls: ActiveCustomerLocationPage = ({ userContext }) => {
  const {
    locations: { data: locationData },
    updateSettings: { fetchStatus: updateFetchStatus, deleteRule },
  } = useAppSelector((state) => state.voipSettings);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedPhoneNumber, setSelectedSettings, selectedSettings, settings, fetchData } = useVoipSettings();

  useEffect(() => {
    fetchData();
  }, []);

  if (!userContext.services.includes(Service.Voip || Service.TaVoip)) {
    dispatch(
      raiseGlobalError({
        message: FriendlyErrorMessage.NO_SERVICES_FOR_LOCATION,
        type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
      }),
    );
  }

  if (!selectedSettings || !selectedPhoneNumber) {
    return null;
  }

  if (deleteRule !== undefined) {
    return (
      <DeleteRuleConfirmation
        voipRuleType={VoipRuleType.REJECT}
        phoneNumber={selectedPhoneNumber}
        ruleAppId={deleteRule}
      />
    );
  }

  function selectNumber(phone: string) {
    setSelectedSettings(phone);
  }

  const toggleRejectAnonymous = () => {
    const newSettings = {
      ...selectedSettings,
      incoming: {
        ...selectedSettings.incoming,
        anonymous: selectedSettings.incoming?.anonymous === 'allow' ? 'reject' : 'allow',
      },
    };
    dispatch(updateVoipSettings(newSettings));
  };
  const toggleRejectForwarded = () => {
    const oldSetting = selectedSettings.incoming?.rejectForwarded === true;
    const newSettings = {
      ...selectedSettings,
      incoming: {
        ...selectedSettings.incoming,
        rejectForwarded: !oldSetting,
      },
    };
    dispatch(updateVoipSettings(newSettings));
  };
  const toggleHiddenNumber = (e: FdsToggleSwitchCustomEvent<FdsToggleSwitchChangeEventDetail>) => {
    e.preventDefault();
    const oldSetting = selectedSettings.outgoing?.hiddenNumber === true;
    const newSettings = {
      ...selectedSettings,
      outgoing: {
        ...selectedSettings.outgoing,
        hiddenNumber: !oldSetting,
      },
    };
    dispatch(updateVoipSettings(newSettings));
  };

  return (
    <VoipContainer services={userContext.services}>
      <div className={styles.container}>
        <ContainerFixed className={styles.tabbedContainer}>
          <VoipSettingsTitle />

          <TabbedContent selectedTabKey={'calls'} tabList={voipSettingsTabs}>
            <ContainerFixed isNarrow={true} className={styles.section}>
              <NumberDropDown
                selectNumber={selectNumber}
                selectedPhoneNumber={selectedPhoneNumber}
                className={styles.chooseNumber}
              />

              <FdsToggleSwitch
                fullWidth={true}
                framed={true}
                defaultChecked={selectedSettings.incoming?.anonymous === 'reject'}
                description={t('pages.voip.rejection.rejectAnonymousDescription')}
                disabled={updateFetchStatus === FetchStatus.PENDING}
                onFdsToggleSwitchChecked={toggleRejectAnonymous}
              >
                {t('pages.voip.rejection.rejectAnonymousCalls')}
              </FdsToggleSwitch>
              <FdsToggleSwitch
                fullWidth={true}
                framed={true}
                defaultChecked={selectedSettings.incoming?.rejectForwarded === true}
                disabled={updateFetchStatus === FetchStatus.PENDING}
                onFdsToggleSwitchChecked={toggleRejectForwarded}
              >
                {t('pages.voip.rejection.rejectForwardedCalls')}
              </FdsToggleSwitch>
              <FdsToggleSwitch
                fullWidth={true}
                framed={true}
                defaultChecked={selectedSettings.outgoing?.hiddenNumber === true}
                disabled={updateFetchStatus === FetchStatus.PENDING}
                onFdsToggleSwitchChecked={(e) => {
                  toggleHiddenNumber(e);
                }}
                className={styles.bottomGap}
              >
                {t('pages.voip.calls.hideOutgoingNumber')}
              </FdsToggleSwitch>

              <Typography
                component="h3"
                variant="formds-altibox-subtitle-secondary"
                className={styles.header}
                bold={true}
              >
                {t('pages.voip.rejection.title')}
              </Typography>
              {locationData?.map((location) =>
                location.phoneNumbers
                  ?.filter((phone) => phone === selectedPhoneNumber)
                  .map((phone) => {
                    return (
                      <RejectionCard
                        location={location}
                        isEditable={true}
                        key={phone}
                        phone={phone}
                        settings={settings}
                      />
                    );
                  }),
              )}
            </ContainerFixed>
          </TabbedContent>
        </ContainerFixed>
      </div>
    </VoipContainer>
  );
};
