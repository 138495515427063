import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

interface NotificationsParams {
  customerCrmId: string;
  servicePointId: string;
}

export const fetchOperationalMessages = createAsyncThunk<
  MinesiderBackend.OperationalMessage[],
  number,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('operationalMessages/fetchOperationalMessages', async (siteId: number, thunkApi) =>
  minesiderBackendService
    .getOperationalMessages(siteId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);

export const fetchNotifications = createAsyncThunk<
  MinesiderBackend.CustomerNotification[],
  NotificationsParams,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('operationalMessages/fetchNotifications', async (params, thunkApi) =>
  minesiderBackendService
    .getNotifications(params.customerCrmId, params.servicePointId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse)),
);
