import { ContainerFixed } from 'app/components/container';
import styles from './information-verification-prompt.module.scss';

export const VerificationContainer = ({ children: components }: React.PropsWithChildren) => (
  <div
    className={styles.confirmation}
    role="dialog"
    aria-labelledby="confirmationLabel"
    aria-describedby="confirmationContent"
  >
    <ContainerFixed isNarrow={true} className={styles.container}>
      {components}
    </ContainerFixed>
  </div>
);
